import React, { Component } from 'react'
import { Confirm } from 'semantic-ui-react'

class PopupModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props,
            open: true
        }
    }

  show = () => this.setState({ open: true })
  handleConfirm = () => {
      this.setState({ open: false }, () => {
        this.state.onConfirm()
      })
  }
  handleCancel = () => {
      this.setState({ open: false }, () => {
        this.state.onCancel()
      })
  }

  render() {
    const { open, header, content } = this.state

    return (
      <div>
        <Confirm
          open={open}
          header={header}
          content={content}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          confirmButton='Confirm'
          size='mini'
        />
      </div>
    )
  }
}

export default PopupModal