import React, {Component} from 'react';

import {
    Search,
    Input
  } from 'semantic-ui-react'
import { SiteID } from '../../utility/Global'
import { makeRequestWithCallback } from '../../utility/Services'

const resultRenderer = ({ name }) => <label>{name}</label>

export default class AdditionalSearchInput extends Component {
  state = {
    className: this.props.className ?? 'info-search',
    size: this.props.size ?? 'small',
    icon: this.props.icon ?? 'search',
    iconPosition: this.props.iconPosition ?? 'left',
    isLoading: false,
    selected: false,
    results: [],
    value: this.props.entity ?? ''
    }
  
  handleResultSelect = (e, { result }) => {
    this.props.onSearchResult(result)

    this.setState({selected: true, value: result.name})
  }
  
  handleSearchChange = async (e, { value }) => {
    const {requestUrl} = this.props

    this.setState({ isLoading: true, value })
  
    if (value.length < 1) {
      return this.setState({isLoading: false, value: '', results: []})
    }
  
    if (value.length > 0) {
      makeRequestWithCallback(`${requestUrl}/search?q=${value}&site=${SiteID}`, (results) => {
        this.setState({results: results, isLoading: false})
      })
    }
  }

  handleBlur = (e) => {
    const {clearable} = this.props
    const {selected} = this.state
    if (selected) {
      this.setState({isLoading: false, selected: false}) 
    } else {
      const {entity, value} = this.state
      let v = value.replace(/\r?\n|\r/gm, '').trim()
      if (clearable) {
        this.props.onNewEntity(v)
      } else {
        if (v !== '' && v !== entity) {
          // New Entry
          this.props.onNewEntity(v)
        } else {
          this.setState({isLoading: false}) 
        }
      }
    }
  }


   render() {
     const {isLoading, results, icon, iconPosition, className, size, value} = this.state
     const {marginLeft, entity, name, placeholder} = this.props

     return (
      <Search 
      key={entity}
      icon={icon} 
      input={{iconPosition: iconPosition, onBlur: this.handleBlur.bind(this)}} as={Input} 
      style={{marginLeft : marginLeft}} className={className} size={size} type='text' placeholder={placeholder} name={name} autoComplete='off' 
      loading={isLoading && value.length > 0} 
      onSearchChange={this.handleSearchChange.bind(this)} 
      onResultSelect={this.handleResultSelect.bind(this)} 
      results={results} value={value} resultRenderer={resultRenderer} showNoResults={false} />
       )
   } 
  }
