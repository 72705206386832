import React, { useState, useEffect, useRef } from "react";

function handleScriptLoad(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete)
    }
  );
}

async function handlePlaceSelect(updateQuery, updateParts, onChange, autoCompleteRef, autoComplete) {
  const addressObject = autoComplete.getPlace();
  
  const writtenAddress = autoCompleteRef.current.value
  
  const query = addressObject.formatted_address;
  const parts = addressObject.address_components;
  updateQuery(query);
  updateParts(parts)

  onChange(writtenAddress, query, parts)
  //console.log(addressObject);
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const [parts, setParts] = useState({});
  const {onChange} = props
  const autoCompleteRef = useRef(null);
  const autoComplete = {}

  useEffect(() => {
    handleScriptLoad(setQuery, setParts, onChange, autoCompleteRef, autoComplete)
  }, []);
  
  // useEffect(() => {
  //   onChange(query)
  //   // eslint-disable-next-line
  // }, [query])

  return (
    <div className='ui transparent input ui small search info-search'
    style={{marginLeft : props?.marginLeft, width: '75%'}} 
    >
      <div class="ui left icon input">
      <input
        name={props?.name}
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        //onBlur={() => {onChange(query, parts)}}
        placeholder={props?.placeholder}
        defaultValue={props.value}
        size='small'
        transparent
      />
      <i aria-hidden="true" className="search icon"></i>
      </div>
    </div>
  );
}

export default SearchLocationInput;