import React, { Component } from 'react'
import _ from 'lodash'
import memoize from 'memoize-one';

import { BuilderPathURL } from '../utility/Global'
import { RenderLoadingMessage, RenderErrorMessage, WYSIWYGLabel, TransitionContainer } from '../utility/GlobalComponents'
import { makeGetRequest, makePostRequest } from '../utility/Services'

import { cemaStatus, cemaStatusColors } from '../utility/Data';

import { Container, Label, Button, Segment, Checkbox, Icon } from 'semantic-ui-react'

import Datatable from 'react-data-table-component'
import { formatDate } from '../utility/Helper';
import { customStyles, downloadCSV, FilterComponent } from '../utility/TableHelper';

const csvColumns = {
  property: 'Property',
  borrower: 'Borrower',
  bank: 'Bank',
  loanOfficer: 'Loan Officer',
  status: 'Status',
  closingDate: 'Closing Date',
  cemaOrdered: 'CEMA Ordered',
  payoffOrdered: 'Payoff Orderd',
  goodThroughDate: 'Good Through Date'
}


export class Dashboard extends Component {
  static displayName = Dashboard.name;

  state = {
     data: [], 
     isLoading: true,
     filterText: '',
     filteredItems: [],
     showAll: false 
    }  


  columns = [
    {
      name: ' ',
      width: '32px',
      selector: 'priority',
      sortable: true,
      cell: row => <>{row.isImmediate ? (<Icon name='exclamation' color='red'/> ) : null }{row.isPriority ? (<Icon name='star' color='yellow' /> ) : null}</>
   },
      {
          name: 'Loan Officer',
          selector: 'loanOfficer',
          sortable: true
      },
      {
          name: 'Borrower',
          selector: 'borrower',
          sortable: true,
          grow: 1.25,
          wrap: true
      },
      {
          name: 'Property',
          selector: 'property',
          sortable: true,
          grow: 1.5,
          format: row => <WYSIWYGLabel message={row.property} />
      },
      {
          name: 'Bank',
          selector: 'bank',
          sortable: true,
          grow: 1.25,
      },
      {
          name: 'CEMA Ordered',
          selector: 'cemaOrdered',
          sortable: true,
          center: true,
          format : row => (row.cemaOrdered ? `${formatDate((row.cemaOrdered))}` : '-')
      },
      {
          name: 'Payoff Ordered',
          selector: 'payoffOrdered',
          sortable: true,
          center: true,
          format : row => (row.payoffOrdered ? `${formatDate((row.payoffOrdered))}` : '-')
      },
      {
          name: 'Good Through Date',
          selector: 'goodThroughDate',
          sortable: true,
          center: true,
          format : row => (row.goodThroughDate ? `${formatDate((row.goodThroughDate))}` : '-')
      },
      {
          name: 'Status',
          selector: 'status',
          sortable: true,
          grow: 1,
          cell: row => <Label className='cema-status-label' style={{width: '100%', textAlign: 'center', fontSize: '11px'}} color={cemaStatusColors[row.status-1]}>{cemaStatus[row.status-1]}</Label>
      },
      {
          name: 'Closing Date',
          selector: 'closingDate',
          sortable: true,
          center: true,
          format : row => (row.closingDate ? `${formatDate((row.closingDate))}` : '-')
      },
  ]

  async componentDidMount() {
    const data = await makeGetRequest(`${BuilderPathURL}/getcemagroups`)

    this.setState({data, isLoading: false}, () => {
      this.setFilter('')
    })
  }

  async createNewCemaGroup() {
    const result = await makePostRequest(BuilderPathURL)
   
    if (result != null) {
      this.props.history.push('/groups/'+result)
    }
  }

  onRowClicked = (row, event) => {
    this.props.history.push(`/groups/${row.id}`)
  }

  handleClear = () => {
    this.setFilter('')
  }

  handleFilter = (e) => {
    const val = e.target.value
    if (val === '') {
      this.handleClear()
      return
    }
    this.setFilter(val)
  }

  handleAllToggle = () => {
    const {showAll, filterText} = this.state
    this.setState({showAll: !showAll}, () => {
      this.setFilter(filterText)
    })
  }

  setFilter = (val) => {
    const {data, showAll} = this.state

    let tmpData = [...data]

    if (!showAll) {
      tmpData = tmpData.filter(item => 
        item.status < cemaStatus.length-1
      )
    }

    let filteredItems
    if (val !== '') {
      filteredItems = tmpData.filter(item => 
        (item.loanOfficer?.toLowerCase().includes(val.toLowerCase()) ||
        item.borrower?.toLowerCase().includes(val.toLowerCase()) ||
        item.property?.toLowerCase().includes(val.toLowerCase()) ||
        item.bank?.toLowerCase().includes(val.toLowerCase()) ||
        item.cemaOrdered?.toLowerCase().includes(val.toLowerCase()) ||
        item.payoffOrdered?.toLowerCase().includes(val.toLowerCase()) ||
        item.goodThroughDate?.toLowerCase().includes(val.toLowerCase()) ||
        item.closingDate?.toLowerCase().includes(val.toLowerCase()) ||
        cemaStatus[item.status-1]?.toLowerCase().startsWith(val.toLowerCase()))
      )
    } else {
      filteredItems = tmpData
    }

    this.setState({filterText: val, filteredItems})
  }

  customSort = (rows, field, direction) => {
    const handleField = row => {
    if (field === 'priority') {
        const {isImmediate, isPriority} = row
        const score = isImmediate && isPriority ? 3 : isImmediate && !isPriority ? 2 : !isImmediate && isPriority ? 1 : 0
        return score
    }
    else if (row[field]) {
      
      if (typeof row[field] === 'string') {
         return row[field].toLowerCase();
      }
    }

    return row[field];
  };

  return _.orderBy(rows, handleField, direction)
}

  renderDashboard() {
    const {filteredItems, filterText, showAll} = this.state
        return (
            <Datatable
            noHeader={true}
            title='Dashboard'
            persistTableHead
            columns={this.columns}
            data={filteredItems}
            defaultSortField='loanOfficer'
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight='75vh'
            sortFunction={this.customSort}
            striped
            onRowClicked={this.onRowClicked.bind(this)}
            responsive
            subHeaderAlign='left'
            subHeader
            subHeaderComponent={(
              <Segment basic style={{width: '100%'}}>
              <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
              <div style={{marginLeft: '2em', display:'inline'}}>
                <em>Active</em>
                <Checkbox style={{marginLeft:'1em', marginRight:'1em', verticalAlign: 'text-bottom'}} toggle checked={showAll} onChange={this.handleAllToggle} />
                <em>All</em>
              </div>
              <Button floated='right' type='button' content='New Group' onClick={this.createNewCemaGroup.bind(this)} positive/>
              <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(filteredItems, 'dashboard', csvColumns)}}/>
              </Segment>
            )}
            />
        )
  }

  render() {
    const {data, isLoading} = this.state
    let contents = isLoading ? RenderLoadingMessage() : data === null ? RenderErrorMessage() : this.renderDashboard()

    return (
      <TransitionContainer className='Dashboard' style={{padding: '0 15em'}}>
        {contents}
      </TransitionContainer>
    );
  }
}

