import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import { formatDate } from '../../utility/Helper';

export default class DateInput extends Component {
  state = {
    picker: false
  }

  handleBlur = (e, {name, value, type} = e.target) => {
    const {onBlur} = this.props

    let dateValue = value.replaceAll('-','/')

    let parts = dateValue.split('/')//split(/[-/]/)
    if (parts.length === 2) {
      dateValue = dateValue + "/" + new Date().getUTCFullYear().toString()
    }

    let date = new Date(dateValue)// new Date(parts[0], parts[1], parts[2])

    let formattedDate = formatDate(date)

    let newValue = formattedDate === 'N/A' ? '' : formattedDate

    onBlur({
      target : {
        name: name,
        value: newValue,
        type: 'date'
      }
    }) 

    this.setState({value: newValue, picker: false})
  }

  render() {
    const {value, className, style, name, label = null, placeholder = 'MM/DD/YYY', size='mini', transparent = false} = this.props
    const {picker} = this.state
    const type = picker ? 'date' : 'text'

    return (
      <>
      <Input key={value} 
        label={label}
        onDoubleClick={() => {this.setState({picker: !picker})}}
        className={className} style={style} size={size} type={type} transparent={transparent} name={name}
        placeholder={placeholder} autoComplete='off' defaultValue={value} onBlur={this.handleBlur.bind(this)} />
      </>
    )
  }
}
