import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './utility/CSS-Importer'

import { makeAuthenticator, Callback } from 'react-oidc'
import { userManager } from './utility/Auth';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


const AppWithAuth = makeAuthenticator({
  userManager: userManager,
  signinArgs: {
    state: {
      path: window.location.pathname
    }
  }
})(App)

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
      <Switch>
      <Route
        path="/callback"
        render={routeProps => (
          <Callback
            onSuccess={user => {
              // `user.state` will reflect the state that was passed in via signinArgs.
              if (user.state) {
                routeProps.history.push(user.state.path)
              }
            }}
            onError={error => {
              console.log(error)
            }}
            userManager={userManager}
          />
        )}
      />
      <AppWithAuth />
      </Switch>
  </BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

