import { makeUserManager } from 'react-oidc'

const passRoles = ["SuperAdmin", "SuperMember", "CemaAdmin", "CemaMember"]

const config = {
  authority: process.env.REACT_APP_SSO_AUTHORITY_URL,
  client_id: process.env.REACT_APP_SSO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile roles cema-api property-api',
  post_logout_redirect_uri: process.env.REACT_APP_SSO_LOGOUT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_SSO_SILENT_RENEW_URI
}

export const userManager = makeUserManager(config)

export const getUser = async () => {
  const user = await userManager.getUser()
  //console.log(user)
  return user
}

export const getUserDisplayName = (name) => {
  return typeof name === 'string' ? name : name[name.length-1]
}

export const userSignin = () => {
  userManager.signinRedirect()
}

export const userSignout = () => {
  userManager.signoutRedirect()
}

export const userAuthorized = (role) => {
  var result = false
  if (typeof role === 'string') {
    if (passRoles.includes(role)) {
      result = true
    }
  } else {
    if (passRoles.some(_ => role.indexOf(_) >= 0)) {
      result = true
    }
  }
  return result
}