import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Group } from './components/Group';
import { Dashboard } from './components/Dashboard';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { getUser, userAuthorized } from './utility/Auth'
import { Contacts } from './components/Contacts';

library.add(fab, faArrowRight)

const routes = [{
  path: '/',
  component: Dashboard,
}, {
  path: '/Groups',
  component: Group,
}, {
  path: '/Groups/:id',
  component: Group,
}, {
  path: '/Contacts',
  component: Contacts
}];


export default class App extends Component {
  static displayName = App.name;

  state = {
    isAuthorized: true
  };

  async componentDidMount() {
    const user = await getUser()

    if (userAuthorized(user.profile.role)) {
      
    } else {
      this.setState({isAuthorized: false})
    }
  }

  render () {
    const routeComponents = routes.map(({path, component}, key) => <Route exact path={path} component={component} key={key}/>);
    const {isAuthorized} = this.state
    return (
      <Layout>
        {isAuthorized ? (
          routeComponents) : <em>Access Denied</em>}
      </Layout>
    );
  }
}