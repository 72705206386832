import moment from 'moment';

import {NotAvailable} from './Global'
import {getObject, isMortgageType} from './Data'

export function createNew(o) {
    return JSON.parse(JSON.stringify(o))
  }
  
export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

export function getStopParts(stop) {
  return {
    stop: stop.stop,
    transferStop: stop.transferStop
  }
  //return stop.match(/([0-9]+)([A-Za-z]*)/)
}

export function getLetterFromIndex(index) {
  return String.fromCharCode((index-1)+97)
}

export function nextLetterIndex(letter) {
    const lToN = (letter.charCodeAt(0) - 97)+1
    const newLetter = String.fromCharCode(lToN + 97)

    return newLetter
  }

export function previousLetterIndex(letter) {
    const lToN = (letter.charCodeAt(0) - 97)-1
    const newLetter = String.fromCharCode(lToN + 97)

    return newLetter
  }

export function formatDate(date) {
    const result = moment.utc(date).format('l')
    if (result === 'Invalid date') {
      return NotAvailable
    }
    return result
  }

export function formatDateWithTime(date) {
  const result = moment(date).format('l LT')
  if (result === 'Invalid date') {
    return NotAvailable
  }
  return result
}

export function textForDate(date) {
    return date === null ? '' : `; Dated ${formatDate(date)}`
}
  
export function textForAmount(amount) {
    return amount === 0 ? '' : `; for ${formatNumber(amount)}`
}

export function textForMers(isMers) {
    return isMers ? 'MERS as nominee for ' : ''
}

export function textForCopy(isCopy) {
    return ''// isCopy ? 'C/C of ' : ''
}

export function formattedStopLabel(stopsLabel) {
    return stopsLabel.split(",").sort().join(",")
}

export function getConsolidatedStopLabel(stop) {
    if (isMortgageType(stop.type) || stop.consolidationFrom === undefined || stop.consolidationFrom.length === 0) {
      return stop.stop.toString()
    } else {
      return `${getConsolidatedStopLabels(stop.consolidationFrom)},${stop.stop.toString()}`
    } 
  }
    
export function getConsolidatedStopLabels(stops) {
  if (stops === undefined) return ''
  let result = ''
  stops.forEach((id, index) => {
      const stop = getObject(id)
      if (stop) {
          result += getConsolidatedStopLabel(stop)
      if (index < stops.length - 1) {
          result += ","
      }
    }    
  })

  return result
}

export function formatNumber(amount) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: (amount % 1) > 0.0 ? 2 : 0
  });
  
  return "$"+formatter.format(amount);
  };

 export function ConvertCurrencyToNumberString(amount) {
   if ( amount === null || (typeof amount === 'string' && amount.length === 0)) return 0
   if ( typeof amount !== 'string' ) return amount
  return amount.replace(/[^0-9.-]+/g,"")
 }