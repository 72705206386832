import React, { Component } from 'react'

import { Container, Menu, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ManageProfileURL } from '../utility/Global'
import { userSignout, userSignin, getUser, getUserDisplayName } from '../utility/Auth'
import { UserData } from 'react-oidc'

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  state = {
    isLoggedIn: false   
  };

  async componentDidMount() {
    const isLoggedIn = (await getUser())

    this.setState({isLoggedIn})
  }

  render () {
    const { isLoggedIn } = this.state
    return (
      <header style={{marginBottom: '4em'}}>
        <Menu className='navigation-lightblue' borderless fixed='top'>
          <Container>
            <Menu.Item as={Link} to='/' header>
              {/**<Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />**/}
              CEMA
            </Menu.Item>
            <Menu.Item position='right' as={Link} to='/'>Dashboard</Menu.Item>
            <Menu.Item as={Link} to='/contacts'>Contacts</Menu.Item>
            { isLoggedIn ? (
              <UserData.Consumer>
                {context => (
                  <Dropdown text={ getUserDisplayName(context.user.profile.name) } className='link item'>
                  <Dropdown.Menu>
                    <Dropdown.Item as='a' href={ManageProfileURL} target='_blank' >Profile</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => {userSignout()}}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                )}
              </UserData.Consumer>
            ) : (
              <Menu.Item onClick={(e) => {userSignin()}}>Login</Menu.Item>
            )}
          </Container>
        </Menu>
        </header>
    );
  }
}