import React, {Component} from 'react'
import MaskedInput  from 'react-text-mask'
import InfoCurrencyInput from './InfoCurrencyInput'

import {Dropdown, Grid, Input, Segment, Loader, Checkbox, Divider, Button} from 'semantic-ui-react'

import ContainedAccordion from './ContainedAccordion'
import { BuilderPathURL, getPropertyAddress, getBorrowerName, getReferralName, APIPathURL, BorrowerPathURL, LoanOfficerPathURL, ZolaSearchURL, OtherPropertyPathURL, PlutoCounties, AttorneyPathURL } from '../../utility/Global'
import { makePutRequest, makeGetRequest, makePostRequest, makeRequestWithCallback } from '../../utility/Services'
import { isEmpty, ConvertCurrencyToNumberString, formatDate } from '../../utility/Helper'
//import AddressSearchInput from './AddressSearchInput'
import AdditionalSearchInput from './AdditionalSearchInput'
import { addToMortgagors } from '../../utility/Data'
import SearchLocationInput from './SearchLocationInput'
import BankInput from './BankInput'
import DateInput from './DateInput'
import moment from 'moment';

const InfoInput = ({className, style, name, placeholder, value, onBlur, size='mini', type='text', transparent = false}) => (
    <Input onDoubleClick={() => {console.log("Clicked")}} key={value} className={className} style={style} size={size} type={type} transparent={transparent} name={name} placeholder={placeholder} autoComplete='off' defaultValue={value} onBlur={onBlur} />
  )

const CheckboxInput = ({style, name, value, onChange, toggle = false}) => (
    <Checkbox key={value} className={toggle ? 'toggle' : ''}  style={{...style, verticalAlign: 'text-bottom' }} size='mini' name={name} checked={value} onChange={onChange} />
)

const MoneyInput = ({name, value, onBlur}) => (
    <InfoCurrencyInput key={value} name={name} value={value} onBlur={onBlur} />
)

const SSNInput = ({style, name, value, onBlur, disabled}) => (
    <div className='ui small input transparent' style={style}>
        <MaskedInput key={value} autoComplete='off' type='text' placeholder='xxxx' mask={[/\d/, /\d/, /\d/, /\d/]} 
        name={name} defaultValue={value} onFocus={(e) => e.target.select()} onBlur={onBlur} disabled={disabled} />
    </div>
)


const InfoDropdown = ({name, placeholder, options, value, onChange}) => (
    <div className='ui mini input'>
        <Dropdown size='mini' name={name} placeholder={placeholder} selection options={options} value={value} onChange={onChange} />
    </div>
)

  export default class InfoPanel extends Component {
    constructor(props) {
      super(props)
      this.state = {
        propertyLoading: false}

    }

    componentDidMount() {
        const {borrower} = this.props
        this.addBorrowerToMortgagors(borrower)
    }

    addBorrowerToMortgagors = (borrower) => {
        if (!isEmpty(borrower)) {
            addToMortgagors(borrower.name)
        }
    }

    updateValues = async (name, value) => {
        const {info} = this.props
        
        const originalValue = info[name]?.toString()
        const newValue = value?.toString()

        const originalEmpty = !originalValue || originalValue.trim() === ''
        const newEmpty = !newValue || newValue.trim() === ''
        
        if (originalEmpty && newEmpty) {
            return 
        }

        if (originalValue !== newValue) {
            if (name === 'otherPropertyId')  {
                info['propertyId'] = null
            }
            if (name === 'propertyId')  {
                info['otherPropertyId'] = null
            }
            
            info[name] = newValue

            var result = await makePutRequest(`${BuilderPathURL}/${info.id}`, info)

            //this.props.setState({info: result})
            this.props.updateInfo(result)
        }
    }

    handleScansExpectedDateSelection = async (time) => {
        const {info} = this.props
        const {orderPlaced} = info

        let now = orderPlaced ? new Date(orderPlaced) : new Date();
        now.setDate(now.getDate() + time * 7);

        this.updateValues('scansExpected', formatDate(now))
    }

    handleBlur = async (e, {name, value, type} = e.target) => { // Need to use e.target here to play along with the Currency Input
        var trimmedValue = typeof(value) === 'string' ? value.trim() : value
        await this.updateValues(name, trimmedValue)
    }

 
    handleMoneyBlur = async (e, {name, value, type} = e.target) => { // Need to use e.target here to play along with the Currency Input        
        await this.updateValues(name, ConvertCurrencyToNumberString(value))
    }

    handleCheckboxChange = async (e, {name, checked}) => { // Need to use e.target here to play along with the Currency Input        
        await this.updateValues(name, checked)
    }

    updateBorrowerValues = async (name, value) => {
        const {borrower} = this.props
        
        const originalValue = borrower[name]
        
        if (originalValue !== value) {
            borrower[name] = value

            await makePutRequest(`${BorrowerPathURL}/${borrower.id}`, borrower)

            this.props.updateBorrower(borrower)
            //this.props.setState({borrower})
        }
    }

    handleBorrowerSSNBlur = async (e, {name, value, type} = e.target) => { // Need to use e.target here to play along with the Currency Input        
        await this.updateBorrowerValues(name, value.replace(/\D/g, ''))
    }

    handlePropertyUpdate = async (lots) => {
        var result
    
        /// TODO: Check all the bbls and see if they are the same
        // if (lots.length > 1) {
            
        // } else {
        //     result = lots[0]
        // }

        result = lots[0]
        let rAddress = result.label.split(',')[0]
        this.setState({propertyLoading: true})
        
        const lot = await makeGetRequest(`${APIPathURL}/getlotbybbl?bbl=${result.bbl}`)

        if (lot) {
            if (lot.address !== rAddress) {
                lot.address = rAddress
            }

            this.setState({propertyLoading: false})

            this.updateValues('propertyId', lot.bbl)

            this.props.updateProperty(lot)
        }  
    }

    handlePropertyAddress =  async (written, address, parts) => {
        if (!parts || isEmpty(parts)) {
            return
        }
        
        var county = parts.find(_ => _.types.includes("administrative_area_level_2"))?.long_name

        if (PlutoCounties.includes(county)) {
            makeRequestWithCallback(`${ZolaSearchURL}&q=${address}`, async (results) => {
                var lots = results.filter(r => r.type === 'lot')
                if (lots.length === 0) {
                    // No matches found in mappluto - There's an issue here
                    // Try the other address written in the input since Google returns a different address sometimes
                    makeRequestWithCallback(`${ZolaSearchURL}&q=${written}`, async (results) => {
                        var lots = results.filter(r => r.type === 'lot')
                        if (lots.length > 0) {
                            this.handlePropertyUpdate(lots)
                        }
                    })
                } else {
                    this.handlePropertyUpdate(lots)
                }
              }, false)
        } else {
            var street_number = parts.find(_ => _.types.includes("street_number"))?.short_name
            var street_name = parts.find(_ => _.types.includes("route"))?.long_name
            var full_address = street_number + " " + street_name

            var city = parts.find(_ => _.types.includes("locality"))?.short_name
            var state = parts.find(_ => _.types.includes("administrative_area_level_1"))?.short_name

            var zip = parts.find(_ => _.types.includes("postal_code"))?.short_name

            var model = {
                address: full_address,
                county: county,
                city: city,
                state: state,
                zipcode: zip
            }

            const lot = await makePostRequest(`${OtherPropertyPathURL}/getotherproperty`, model)

            if (lot) {
                this.setState({propertyLoading: false})

                this.updateValues('otherPropertyId', lot.id)

                this.props.updateProperty(lot)
            }  
        } 
      }

    handleAddressSearchResult = async (result) => {
        let rAddress = result.label.split(',')[0]
        
        this.setState({propertyLoading: true})
        
        const lot = await makeGetRequest(`${APIPathURL}/getlotbybbl?bbl=${result.bbl}`)

        if (lot) {
            if (lot.address !== rAddress) {
                lot.address = rAddress
            }

            this.setState({propertyLoading: false})

            this.updateValues('propertyId', lot.bbl)

            this.props.updateProperty(lot)
        }   
    }

    handleBorrowerSearchResult = async (result) => {        
        if (result) {
            const newBorrower = await makeGetRequest(`${BorrowerPathURL}?id=${result.id}`)

            this.updateBorrower(newBorrower)
        } 
    }

    handleNewBorrower = async (value) => {
        if (value !== '') {
            const borrower = {
                name: value
            }

            // const model = {
            //     borrower: borrower,
            //     siteId: SiteID
            // }

            const result = await makePostRequest(`${BorrowerPathURL}`, borrower)

            this.updateBorrower(result)
        }
    }

    updateBorrower = (result) => {
        if (result) {
            //this.props.setState({borrower: result})
            this.updateValues('borrowerId', result.id)

            this.addBorrowerToMortgagors(result)
            
            this.props.updateBorrower(result)
        }
    }

    handleReferralSearchResult = async (result) => {  
        if (result) {
            const {info} = this.props

            info.loanOfficer = result

            //this.props.setState({info})

            this.updateValues('loanOfficerId', result.id)
            this.forceUpdate()
        } 
    }

    handleNewReferral = async (value) => {
        if (value !== '') {
            const o = {
                name: value
            }

            const result = await makePostRequest(LoanOfficerPathURL, o)

            await this.handleReferralSearchResult(result)
        }
    }

    handleAttorneySearchResult = async (result) => {  
        if (result) {
            const {info} = this.props

            info.cemaAttorney = result

            //this.props.setState({info})

            this.updateValues('cemaAttorneyId', result.id)
        } else {
            this.updateValues('cemaAttorneyId', null)
            this.forceUpdate()
        }
    }

    handleNewAttorney = async (value) => {
        const {info} = this.props
        if (value !== '') {
            if (info.cemaAttorney.nickname === value) {
                return
            }
            const o = {
                name: value
            }

            const result = await makePostRequest(AttorneyPathURL, o)

            await this.handleAttorneySearchResult(result)
        } else {
            if (!info.cemaAttorney) {
                return
            }
            this.updateValues('cemaAttorneyId', null)
            this.forceUpdate()
        }
    }

    handleUnitBlur = async (e, {name, value, type} = e.target) => { // Need to use e.target here to play along with the Currency Input        
        let unit = value.trim()

        if (unit) {
            const {property} = this.props
            const lot = await makeGetRequest(`${APIPathURL}/getlotforunit?address=${property.address}&unit=${unit}`)

            if (lot) {
                this.setState({propertyLoading: false})

                this.updateValues('propertyId', lot.bbl)

                this.props.updateProperty(lot)
            } else {
                alert("Unit not found")
            }
        }
    }

    render() {
      const {loanOfficer, bank, loanNumber, cemaAttorney, originalsExpected, scansExpected, scansReceived, method, bankSent, bankApproved, closingDate, orderFormSent, feeOrderFormDate, subjectToErrors, exchangeScheduled, orderPlaced,
            payoffOrdered, payoffReceived, goodThroughDate, oldMoney} = this.props.info
      const {propertyLoading, borrower, property} = this.props
      
      return (
          <Grid className='info-container'>
              <Grid.Row>
                  <Grid.Column width={5}>
                      <ContainedAccordion
                      titleChildren={[<strong><em>Borrower</em></strong>,
                        <AdditionalSearchInput key={borrower?.id ?? -1} marginLeft="27.5%" onSearchResult={this.handleBorrowerSearchResult.bind(this)} entity={getBorrowerName(borrower)} onNewEntity={this.handleNewBorrower.bind(this)} requestUrl={BorrowerPathURL} name='borrower' placeholder='Borrower Name' />]}
                        contentChildren={
                            <Segment.Group>
                              <Segment.Group compact horizontal>
                                  <Segment basic>
                                  <div className='info-sub-title'><strong><em>Loan Officer</em></strong></div>
                                  </Segment>
                                  <Segment basic>
                                  <AdditionalSearchInput key={loanOfficer?.id ?? -2} onSearchResult={this.handleReferralSearchResult.bind(this)} entity={getReferralName(loanOfficer)} onNewEntity={this.handleNewReferral.bind(this)} requestUrl={LoanOfficerPathURL} name='loanOfficer' placeholder='Loan Officer' />
                                  </Segment>  
                              </Segment.Group>
                            </Segment.Group>
                          }
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                      <ContainedAccordion
                      titleChildren={[<strong><em>SSN #</em></strong>,
                        <SSNInput disabled={isEmpty(borrower)} style={{marginLeft: "18.5%", width: '45%'}} size='small' name='ssn' placeholder='SSN' value={borrower?.ssn} onBlur={this.handleBorrowerSSNBlur.bind(this)} />]}
                    />
                </Grid.Column>
                  <Grid.Column width={8}>
                  <ContainedAccordion
                      titleChildren={[<strong><em>Property</em></strong>,
                        <SearchLocationInput key={getPropertyAddress(property)} name='address' label='Addess' placeholder='Enter an Address' marginLeft="10%" onChange={this.handlePropertyAddress.bind(this)} value={getPropertyAddress(property)} />]}
                      //<AddressSearchInput marginLeft="10%" onSearchResult={this.handleAddressSearchResult.bind(this)} address={getPropertyAddress(property)} />]}
                      contentChildren={
                      <Segment.Group>
                          {
                              !isEmpty(property) && property.block && property.lot ? (
                                <Segment.Group compact horizontal>
                                <Segment style={{minWidth: '130px', maxWidth: '130px'}} basic>
                                <em>Block / Lot</em>
                                </Segment>
                                <Segment basic>
                                    {!isEmpty(property) ? (
                                        <label>{property.block} / {property.lot}</label>
                                    ) : <Loader active={propertyLoading} inline size='mini' /> }
                                </Segment>
                                { !isEmpty(property) && property.isCondo ? (
                                    <>
                                    <Segment style={{maxWidth: '50px'}} basic>
                                    <em>Unit</em>
                                    </Segment>
                                    <Segment basic>
                                        <InfoInput transparent={true} name='unit' size='small' placeholder='Unit' value={property.unit} onBlur={this.handleUnitBlur.bind(this)} />
                                    </Segment>
                                    </>
                                ) : null }
                            </Segment.Group>
                              ) : null
                          }
                      </Segment.Group>
                    }
                    />
                  </Grid.Column>
              </Grid.Row>
              <Divider/>
              <Grid.Row>
                <Grid.Column width={4}>
                  <ContainedAccordion
                      titleChildren={[<strong><em>CEMA Info</em></strong>]}
                      contentChildren={
                      <Segment.Group>
                          <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Bank</em>
                            </Segment>
                            <Segment basic style={{marginLeft: '0.5em', width: '100%'}}>
                                <BankInput name='bankId' placeholder='Bank' value={bank?.name} onChange={this.handleBlur.bind(this)} />
                                {/* <InfoInput name='bank' placeholder='Bank' value={bank} onBlur={this.handleBlur.bind(this)} /> */}
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>CEMA Attorney</em>
                            </Segment>
                            <Segment basic>
                            <AdditionalSearchInput key={cemaAttorney?.id ?? -3} 
                                clearable={true}
                                className='attorney-search'
                                size='mini'
                                icon={''}
                                iconPosition='none'
                                onSearchResult={this.handleAttorneySearchResult.bind(this)} 
                                entity={cemaAttorney?.nickname} 
                                onNewEntity={this.handleNewAttorney.bind(this)} 
                                requestUrl={AttorneyPathURL} name='cemaAttorney' placeholder='Attorney' />
                                {/* <InfoInput name='cemaAttorney' placeholder='CEMA Attorney' value={cemaAttorney?.nickname} onBlur={this.handleBlur.bind(this)} /> */}
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Order Form Sent</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='orderFormSent' value={orderFormSent} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Fee/Order Form Rec'd</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='feeOrderFormDate' value={feeOrderFormDate} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Order Placed</em>
                            </Segment>
                            <Segment basic>
                                <DateInput name='orderPlaced' value={orderPlaced} onBlur={this.handleBlur.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                      </Segment.Group>
                    }
                    />
                  </Grid.Column>
                <Grid.Column width={4}>
                <ContainedAccordion
                    titleChildren={[<strong><em>CEMA Documents</em></strong>]}
                    contentChildren={    
                    <Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        </Segment>
                        <Segment basic>
                            <Button style={{width: '45%'}} circular size='mini' content='6' onClick={this.handleScansExpectedDateSelection.bind(this, 6)} />
                            <Button style={{width: '45%'}} circular size='mini' content='8' onClick={this.handleScansExpectedDateSelection.bind(this, 8)} />
                        </Segment>
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Scans Expected</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='scansExpected' value={scansExpected} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Scans Received</em>
                        </Segment>
                        <Segment basic>
                        <DateInput name='scansReceived' value={scansReceived} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Originals Expected</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='originalsExpected' value={originalsExpected} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Method for exchange</em>
                        </Segment>
                        <Segment basic>
                            <InfoInput name='method' placeholder='' value={method} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                </Segment.Group>
                }
                />
                </Grid.Column>
                <Grid.Column width={4}>
                  <ContainedAccordion
                      titleChildren={[<strong><em>CEMA Approval/Closing</em></strong>]}
                      contentChildren={
                        <Segment.Group>
                            <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Sent to bank for appvl</em>
                            </Segment>
                            <Segment horizontal basic>
                            <DateInput name='bankSent' value={bankSent} onBlur={this.handleBlur.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Subject to errors</em>
                            </Segment>
                            <Segment basic>
                            <CheckboxInput name='subjectToErrors' value={subjectToErrors} onChange={this.handleCheckboxChange.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Bank Approved</em>
                            </Segment>
                            <Segment basic>
                                <DateInput name='bankApproved' value={bankApproved} onBlur={this.handleBlur.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Closing Date</em>
                            </Segment>
                            <Segment basic>
                            <DateInput name='closingDate' value={closingDate} onBlur={this.handleBlur.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                        <Segment.Group compact horizontal>
                            <Segment basic>
                            <em>Exchange Scheduled</em>
                            </Segment>
                            <Segment basic>
                                <CheckboxInput name='exchangeScheduled' value={exchangeScheduled} onChange={this.handleCheckboxChange.bind(this)} />
                            </Segment>  
                        </Segment.Group>
                    </Segment.Group>
                    }
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                <ContainedAccordion
                    titleChildren={[<strong><em>Payoff Info</em></strong>]}
                    contentChildren={    
                    <Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Loan #</em>
                        </Segment>
                        <Segment basic>
                            <InfoInput name='loanNumber' placeholder='Loan #' value={loanNumber} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Payoff Ordered</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='payoffOrdered' value={payoffOrdered} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Payoff Rec'd</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='payoffReceived' value={payoffReceived} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Good Through Date</em>
                        </Segment>
                        <Segment basic>
                            <DateInput name='goodThroughDate' value={goodThroughDate} onBlur={this.handleBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                    <Segment.Group compact horizontal>
                        <Segment basic>
                        <em>Unpaid Principal</em>
                        </Segment>
                        <Segment basic>
                            <MoneyInput name='oldMoney' value={oldMoney} onBlur={this.handleMoneyBlur.bind(this)} />
                        </Segment>  
                    </Segment.Group>
                </Segment.Group>
                }
                />
                </Grid.Column>
              </Grid.Row>
          </Grid>
      )
    }
  }

