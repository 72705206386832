import React, {Component} from 'react';

import {isCemaType, isMortgageType} from '../../utility/Data'
import {textForAmount, textForCopy, textForDate, textForMers, isEmpty} from '../../utility/Helper'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {List, Checkbox, Grid, Divider} from 'semantic-ui-react'
import { BuilderPathURL } from '../../utility/Global'
import { makePutRequest } from '../../utility/Services'

function checklistTitleLabel(key, section) {
  if (isCemaType(section.type) && !isSectionNewMoney(section)) {
    return 'Mtg ' + key + ' Cons.'
  }
  else {
    return 'Mtg ' + key 
  }
}

function isSectionNewMoney(section) {
  return section.notes.length > 1 && section.copies.length > 1
}

const CoverPageNoteGroup = ({note, allonges}) => (
  <List.Item>
    <strong>{isCemaType(note.section) ? 'Consolidated ' : ''}Note</strong> for {note.mortgagor}&nbsp;&nbsp;<FontAwesomeIcon icon="arrow-right"/>&nbsp;&nbsp;{note.mortgagee}{textForDate(note.date)}{textForAmount(note.amount)}
    {allonges.length > 0 ? (
      <List.List className='cover-page-list-allonges'>
      {allonges.map((allonge, index) => {
        return <CoverPageAllonge key={index} allonge={allonge} />
      })}
    </List.List>
    ) : null }
  </List.Item>
)

const CoverPageCopy = ({copy}) => (
  <List.Item>
    {console.log(copy)}
    { isMortgageType(copy.section) ? (
      
      <CoverPageMortgageCopy copy={copy} />
    ) : (
      <CoverPageCEMACopy copy={copy} />
    )}
  </List.Item>
)

const CoverPageMortgageCopy = ({copy}) => (
  <span><strong>{textForCopy(copy.isCopy)}{copy.section}</strong>{textForAmount(copy.amount)}</span>
)

const CoverPageCEMACopy = ({copy}) => (
  <span><strong>{textForCopy(copy.isCopy)}{copy.section}</strong> from {copy.mortgagor}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{copy.mortgagee}{textForDate(copy.date)}{textForAmount(copy.amount)}</span>
)

const CoverPageAllonge = ({allonge}) => (
  <List.Item><strong>Allonge</strong> from {allonge.personOne}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{allonge.personTwo}</List.Item>
)

const CoverPageAssignment = ({assignment}) => (
  <List.Item><strong>{textForCopy(assignment.isCopy)}Assignment</strong> from {textForMers(assignment.fromMers)}{assignment.personOne}&nbsp;&nbsp;<FontAwesomeIcon icon='arrow-right'/>&nbsp;&nbsp;{textForMers(assignment.isMers)}{assignment.personTwo}{textForDate(assignment.date)}</List.Item>
)

const CoverPageSection = ({note, copy, allonges}) => (
  <List bulleted className='cover-page-list'>
    {note ? (
      <CoverPageNoteGroup note={note} allonges={allonges} />
    ) : null}
    <CoverPageCopy copy={copy} />
  </List>
)

const CoverPageAssignmentsSection = ({assignments}) => (
  <List bulleted className='cover-page-list'>
    {assignments.map((assignment, index) => {
      return <CoverPageAssignment key={index} assignment={assignment} />
    })}
  </List>
)


  export default class CoverPage extends Component {
    itemsToUpdate = []
    updateTimer = null
    
    updateChecklist = async (event, {scan, original, item, checked}) => {
      event.preventDefault()

      if (this.updateTimer !== null) {
        clearTimeout(this.updateTimer)
      }
  
      const {cemaGroupId, closed} = this.props

      if (closed) {
        return
      }
  
  //    var model = {...item}
  
      if (scan) {
        //model.isScan = checked
        item.isScan = checked
      } else {
        //model.isOriginal = checked
        item.isOriginal = checked
      }

      this.itemsToUpdate.push(item)

      this.forceUpdate()

      this.updateTimer = setTimeout(async () => {
        const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupId}/updatechecklist/`, this.itemsToUpdate)

        if (result && result.message == "Success") {
          // note.isScan = result.isScan
          // note.isOriginal = result.isOriginal
        } else {
          // Might need to change back to previous
        }

        this.itemsToUpdate = []
      }, 1000)
    }

    // updateChecklistNote = async (event, {scan, original, note, checked}) => {
    //   event.preventDefault()
    //   if (this.updateTimer !== null) {
    //     clearTimeout(this.updateTimer)
    //   }
  
    //   const {cemaGroupId, closed} = this.props

    //   if (closed) {
    //     return
    //   }
  
    //   var model = {...note}
  
    //   if (scan) {
    //     model.isScan = checked
    //   } else {
    //     model.isOriginal = checked
    //   }

    //   this.notesToUpdate.push(model)

    //   this.updateTimer = setTimeout(() => {
    //     const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupId}/updatechecklistnote/`, notesToUpdate)

    //     if (result) {
    //       note.isScan = result.isScan
    //       note.isOriginal = result.isOriginal
    //       this.forceUpdate()
  
    //       notesToUpdate = []
    //     }
    //   }, 500)
    // }
  
    // updateChecklistAssignment = async (event, {scan, original, assignment, checked}) => {
    //   event.preventDefault()
  
    //   const {cemaGroupId, closed} = this.props

    //   if (closed) {
    //     return
    //   }

    //   var model = {...assignment}
  
    //   if (scan) {
    //     model.isScan = checked
    //   } else {
    //     model.isOriginal = checked
    //   }

    //   const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupId}/updatechecklistassignment/${assignment.id}`, model)

    //   if (result) {
    //     assignment.isScan = result.isScan
    //     assignment.isOriginal = result.isOriginal
    //     this.forceUpdate()
    //   }
    // }
  
    // updateChecklistAllonge = async (event, {scan, original, allonge, checked}) => {
    //   event.preventDefault()
  
    //   const {cemaGroupId, closed} = this.props

    //   if (closed) {
    //     return
    //   }
  
    //   var model = {...allonge}
  
    //   if (scan) {
    //     model.isScan = checked
    //   } else {
    //     model.isOriginal = checked
    //   }

    //   const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupId}/updatechecklistallonge/${allonge.id}`, model)

    //   if (result) {
    //     allonge.isScan = result.isScan
    //     allonge.isOriginal = result.isOriginal
    //     this.forceUpdate()
    //   }
    // }

    CoverPageNoteCheckboxGroup = (note, allonges) => (
      <List.Item>
        <Checkbox className='cb-scan' scan='scan' item={note} onChange={this.updateChecklist.bind(this)} checked={note?.isScan}/>&nbsp;&nbsp;<Checkbox className='cb-original' original='original' item={note} onChange={this.updateChecklist.bind(this)} checked={note?.isOriginal}/>
        {allonges.length > 0 ? (
          <List.List className='cover-page-list-allonges-checkboxes'>
          {allonges.map((allonge, index) => {
            return <List.Item key={allonge.id}><Checkbox className='cb-scan' scan='scan' item={allonge} onChange={this.updateChecklist.bind(this)} checked={allonge.isScan}/>&nbsp;&nbsp;<Checkbox className='cb-original' original='original' item={allonge} onChange={this.updateChecklist.bind(this)} checked={allonge.isOriginal}/></List.Item>
          })}
        </List.List>
        ) : null }
      </List.Item>
    )

    CoverPageCheckboxSection = (note, copy, allonges) => (
      <List className='cover-page-list'>
        {this.CoverPageNoteCheckboxGroup(note, allonges)}
        <List.Item><Checkbox className='cb-scan' scan='scan' item={copy} onChange={this.updateChecklist.bind(this)} checked={copy?.isScan}/>&nbsp;&nbsp;<Checkbox className='cb-original' original='original' item={copy} onChange={this.updateChecklist.bind(this)} checked={copy?.isOriginal}/></List.Item>  {/**Copy */}
      </List>
    )
    
    CoverPageAssignmentsCheckboxSection = (assignments, onChange) => (
      <List className='cover-page-list'>
        {assignments.map((assignment, index) => {
          return <List.Item key={assignment.id}><Checkbox className='cb-scan' scan='scan' item={assignment} onChange={this.updateChecklist.bind(this)} checked={assignment.isScan}/>&nbsp;&nbsp;<Checkbox className='cb-original' original='original' item={assignment} onChange={this.updateChecklist.bind(this)} checked={assignment.isOriginal}/></List.Item>
        })}
      </List>
    )
    

    render() {
      const {checklist} = this.props
      const shouldRender = !isEmpty(checklist) && Object.entries(checklist).length > 0
      return (
        <section>
          {shouldRender ? (
            <Grid centered style={{marginBottom: '-2rem'}}>
            <Grid.Row>
                <Grid.Column width={2}>
      
                </Grid.Column>
                <Grid.Column style={{paddingLeft: '0.5rem', fontSize: '11.5px'}} textAlign='center' width={1}>
                  <strong style={{color: 'blue'}}>Scn</strong>&nbsp;&nbsp;<strong style={{color: 'green'}}>Org</strong>
                </Grid.Column>
                <Grid.Column width={13}>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        ) : null }
        
        { shouldRender && Object.entries(checklist).map(([key, section]) => {
          const sectionLength = section.copies?.length
           return <Grid centered key={key} className='cover-page-section'>
            <Grid.Row>
                <Grid.Column textAlign='left' width={2}>
                    <span className='cover-page-header'>
                    {checklistTitleLabel(key, section)}
                    </span>
                  </Grid.Column>
             {/* {
               [...Array(sectionLength)].map((elementInArray, index) => ( 
                <Grid.Row>
                <Grid.Column textAlign='left' width={2}>
                    <span className='cover-page-header'>
                    {checklistTitleLabel(key, section)}
                    </span>
                  </Grid.Column>
                  <Grid.Column width={1}>
                  {this.CoverPageCheckboxSection(section.notes[index], section.copies[index], section.allonges.filter(_ => isCemaType(section.type) && !isSectionNewMoney(section) ? isCemaType(_.section) : isMortgageType(_.section)))}
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <CoverPageSection note={section.notes[index]} copy={section.copies[index]} allonges={section.allonges.filter(_ => isCemaType(section.type) && !isSectionNewMoney(section) ? isCemaType(_.section) : isMortgageType(_.section))} /> 
                    </Grid.Column>
                  </Grid.Row>
                  ) 
                )
             } */}
             <Grid.Column width={1}>
               {this.CoverPageCheckboxSection(section.notes[0], section.copies[0], section.allonges.filter(_ => isCemaType(section.type) && !isSectionNewMoney(section) ? isCemaType(_.section) : isMortgageType(_.section)))}
             </Grid.Column>
             <Grid.Column width={13}>
               <CoverPageSection note={section.notes[0]} copy={section.copies[0]} allonges={section.allonges.filter(_ => isCemaType(section.type) && !isSectionNewMoney(section) ? isCemaType(_.section) : isMortgageType(_.section))} /> 
             </Grid.Column>
           </Grid.Row>
           {isCemaType(section.type) && isSectionNewMoney(section) ? (
             <Grid.Row className='cover-page-consolidation-row'>
             <Grid.Column textAlign='left' width={2}>
             <span className='cover-page-header'>Mtg {key} Cons.</span>
             </Grid.Column>
             <Grid.Column width={1}>
             {this.CoverPageCheckboxSection(section.notes[1], section.copies[1], section.allonges.filter(_ => isCemaType(_.section)))}
             </Grid.Column>
             <Grid.Column width={13}>
               <CoverPageSection note={section.notes[1]} copy={section.copies[1]} allonges={section.allonges.filter(_ => isCemaType(_.section))} />
             </Grid.Column>
           </Grid.Row>
           ) : null }
           {section.assignments.length > 0 ? (
           <Grid.Row>
             <Grid.Column width={2}>
             </Grid.Column>
             <Grid.Column width={1}>
                  {this.CoverPageAssignmentsCheckboxSection(section.assignments)}
             </Grid.Column>
             <Grid.Column width={13}>
                  <CoverPageAssignmentsSection assignments={section.assignments} />
             </Grid.Column>
           </Grid.Row>
           ) : null }
         </Grid>
         })}
  
         {shouldRender ? (
           <Divider/>
         ) : null }
  
        </section>
      )
    }
  }


  //<CoverPageCheckboxSection note={section.notes[0]} copy={section.copies[0]} allonges={section.allonges.filter(_ => isMortgageType(_.sectionType))} />

  //<CoverPageCheckboxSection note={section.notes[1]} copy={section.copies[1]} allonges={section.allonges.filter(_ => isCemaType(_.sectionType))} />

  //<CoverPageAssignmentsCheckboxSection assignments={section.assignments}/>