import React, { Component } from 'react'
import _ from 'lodash'
import memoize from 'memoize-one';

import { AttorneyPathURL } from '../utility/Global'
import { RenderLoadingMessage, RenderErrorMessage, WYSIWYGLabel, TransitionContainer } from '../utility/GlobalComponents'
import { makeGetRequest } from '../utility/Services'

import { Button, Segment } from 'semantic-ui-react'

import Datatable from 'react-data-table-component'
import { customStyles, downloadCSV, FilterComponent } from '../utility/TableHelper';

const csvColumns = {
  name: 'Name',
  nickname: 'Nickname',
  address: 'Address',
  phone: 'Phone',
  fax: 'Fax',
  email: 'Email',
  url: 'URL',
}


export class Contacts extends Component {
  state = {
     data: [], 
     isLoading: true,
     filterText: '',
     filteredItems: []
    }  


  columns = [
      {
          name: 'Name',
          selector: 'name',
          sortable: true
      },
      {
          name: 'Nickname',
          selector: 'nickname',
          sortable: true
      },
      {
          name: 'Address',
          selector: 'address',
          sortable: true,
          grow: 1,
          cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.address} /></div>
      },
      {
        name: 'Phone',
        selector: 'phone',
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.phone} /></div>
      },
      {
        name: 'Fax',
        selector: 'fax',
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.fax} /></div>
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.email} /></div>
    },
    {
        name: 'URL',
        selector: 'url',
        sortable: true,
        cell: row => <div className='contact-cell'><WYSIWYGLabel message={row.url} /></div>
    },
    {
        name: 'Banks',
        selector: 'banks',
        cell: row => <div className='contact-bank-cell'>
            {row.banks?.map((item, index) => {
                return <span key={index}>{item?.name}</span>
            })}
        </div>
        
    }
  ]

  async componentDidMount() {
    const data = await makeGetRequest(`${AttorneyPathURL}`)

    this.setState({data, isLoading: false}, () => {
      this.setFilter('')
    })
  }

  handleClear = () => {
    this.setFilter('')
  }

  handleFilter = (e) => {
    const val = e.target.value
    if (val === '') {
      this.handleClear()
      return
    }
    this.setFilter(val)
  }


  setFilter = (val) => {
    const {data} = this.state

    let tmpData = [...data]

    let filteredItems
    if (val !== '') {
      filteredItems = tmpData.filter(item => 
        (item.name?.toLowerCase().includes(val.toLowerCase()) ||
        item.nickname?.toLowerCase().includes(val.toLowerCase()) ||
        item.address?.toLowerCase().includes(val.toLowerCase()) ||
        item.phone?.toLowerCase().includes(val.toLowerCase()) ||
        item.fax?.toLowerCase().includes(val.toLowerCase()) ||
        item.email?.toLowerCase().includes(val.toLowerCase()))
      )
    } else {
      filteredItems = tmpData
    }

    this.setState({filterText: val, filteredItems})
  }

  customSort = (rows, field, direction) => {
    const handleField = row => {
    if (row[field]) {
      
      if (typeof row[field] === 'string') {
         return row[field].toLowerCase();
      }
    }

    return row[field];
  };

  return _.orderBy(rows, handleField, direction)
}

  renderDashboard() {
    const {filteredItems, filterText} = this.state
        return (
            <Datatable
            noHeader={true}
            title='Contacts'
            persistTableHead
            columns={this.columns}
            data={filteredItems}
            defaultSortField='nickname'
            highlightOnHover
            pointerOnHover
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight='75vh'
            sortFunction={this.customSort}
            striped
            responsive
            subHeaderAlign='left'
            subHeader
            subHeaderComponent={(
              <Segment basic style={{width: '100%'}}>
              <FilterComponent onFilter={this.handleFilter} onClear={this.handleClear} filterText={filterText} />
              <Button floated='right' type='button' content='Export' onClick={(e) => {downloadCSV(filteredItems, 'contacts', csvColumns)}}/>
              </Segment>
            )}
            />
        )
  }

  render() {
    const {data, isLoading} = this.state
    let contents = isLoading ? RenderLoadingMessage() : data === null ? RenderErrorMessage() : this.renderDashboard()

    return (
        <TransitionContainer className='Contacts' style={{padding: '0 15em'}}>
        {contents}
      </TransitionContainer>
    );
  }
}

