import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isEmpty, textForCopy, textForMers, textForAmount, textForDate } from "../../utility/Helper";
import { isCemaType } from "../../utility/Data";
import { getBorrowerName, getPropertyAddress, getReferralName } from "../../utility/Global";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function assignmentForExport(assignment) {
    return {text: [ {text: `${textForCopy(assignment.isCopy)}Assignment`, bold: true},` from ${textForMers(assignment.fromMers)}${assignment.personOne}`, {text : '  >  ', bold: true}, `${textForMers(assignment.isMers)}${assignment.personTwo}${textForDate(assignment.date)}`]}
}

function allongeForExport(allonge) {
    return {text: [ {text: 'Allonge', bold: true}, ` from ${allonge.personOne}`, {text : '  >  ', bold: true}, `${allonge.personTwo}`]}
}

function noteForExport(type, note) {
    return {text: [ {text: `${type}Note`, bold: true}, ` for ${note.mortgagor}`, {text : '  >  ', bold: true}, `${note.mortgagee}${textForDate(note.date)}${textForAmount(note.amount)}`]}
}

function copyForMortgageExport(copy) {
  return {text: [ {text: `${textForCopy(copy.isCopy)}${copy.section}`, bold: true},`${textForAmount(copy.amount)}`]}
}

function copyForExport(copy) {
    return {text: [ {text: `${textForCopy(copy.isCopy)}${copy.section}`, bold: true}, ` from ${copy.mortgagor}`, {text : '  >  ', bold: true}, `${copy.mortgagee}${textForDate(copy.date)}${textForAmount(copy.amount)}`]}
}

function labelForHeader(title, text) {
    return { text: [ { text: `${title}:`, style: 'headerTitle'}, `  ${text}`] }
}

export function ChecklistExport(groupInfo, property, borrower, checklist) {
    let content = []
    if (!isEmpty(checklist)) {
        content = Object.entries(checklist).map(([key, section]) => {
          const notes = section.notes
          const copies = section.copies
          const allonges = section.allonges
          const assignments = section.assignments
  
  
          let columns = []
          let mNodeList = []
  
          let stop = notes[0].stop
  
          let assignmentList = {
            ul: assignments.map((assignment) => {
              return assignmentForExport(assignment)
            })
          }
  
          if (!isCemaType(section.type) || (isCemaType(section.type) && notes.length === 2)) {
            let mAllongeList = allonges.filter(_ => !isCemaType(_.section)).map((allonge) => {
              return allongeForExport(allonge)
            })  
  
            let note = notes[0]
            let sectionType = isCemaType(note.section) ? 'Consolidated ' : ''
            let copy = copies[0]
  
            let mNoteItem = {
              ul: [
                noteForExport(sectionType, note),
                {
                  ul: mAllongeList
                },
                !isCemaType(copy.section) ? copyForMortgageExport(copy) : copyForExport(copy)
              ]
            }
  
            mNodeList.push(mNoteItem)
          }
  
          if (isCemaType(section.type)) {
            let cAllongeList = allonges.filter(_ => isCemaType(_.section)).map((allonge) => {
                return allongeForExport(allonge)
            })
  
            let cNodeList = []
  
            let note = notes[notes.length-1]
            let sectionType = isCemaType(note.section) ? 'Consolidated ' : ''
            let copy = copies[copies.length-1]
  
            let cNoteItem = {
              ul: [
                noteForExport(sectionType, note),
                {
                  ul: cAllongeList
                },
                copyForExport(copy)
              ]
            }
  
            cNodeList.push(cNoteItem)
            cNodeList.push(assignmentList)
  
            let group2 = [{
              text: `Mtg ${stop} Cons.`,
              width: '20%'
            },
            {
              ul: cNodeList,
              width: '*'
            }]
  
            columns.push('\n')
            columns.push({columns:group2})
          } else {
            mNodeList.push(assignmentList)
          }
          
          if (!isEmpty(mNodeList)) {
            let group1 = [{
              text: `Mtg ${stop}`,
              width: '20%'
            },
            {
              ul: mNodeList,
              width: '*'
            }]
    
            columns.unshift({columns:group1})
          }
  
          return [columns, '\n']
  
        })
      }

      let docDefinition = {
        content: [
            { stack: [
                labelForHeader('Borrower', getBorrowerName(borrower)),
                labelForHeader('Property', `${getPropertyAddress(property)} ${property ? `(${property?.block} / ${property?.lot})` : ''}`),
                labelForHeader('Loan Officer', getReferralName(groupInfo.loanOfficer))
                ],
                style: 'header'
            },
            content
          ],
        defaultStyle: {
          fontSize: 11,
          lineHeight: 1.1
        },
        styles: {
            header: {
                margin: [0, 0, 0, 25]
            },
            headerTitle: {
                fontSize: 12,
                bold: true
            }
        }
      }

      pdfMake.createPdf(docDefinition).open();
}