import React, { Component, createRef } from 'react';

import {BuilderPathURL, CemaAPIPathURL} from '../utility/Global'
import {ErrorMessage, RenderLoadingMessage, RenderErrorMessage} from '../utility/GlobalComponents'
import {makePostRequest, makeGetRequest, makePutRequest, makeDeleteRequest, uploadFiles, openFile} from '../utility/Services'
import {allObjects, getObjectWhereTransferedTo, addToMortgagors, addToMortgagees, addToBanks, resetData, getObject} from '../utility/Data'
import {StopType, mortgage, transfer, mortgageError, cemaError, aTransferError, mTransferError, isMortgageType, isCemaType, isMTransferType, isATransferType, isTransferType} from '../utility/Data' 
import {createNew, isEmpty, getLetterFromIndex, getConsolidatedStopLabel, formattedStopLabel, ConvertCurrencyToNumberString, formatDateWithTime} from '../utility/Helper'

import {
  Grid,
  Form,
  Checkbox,
  Select,
  Button,
  Divider,
  Segment,
  Ref,
  Rail,
  Sticky,
  Table,
  Icon,
  Input,
  Label,
  Container,
  Card,
  Dimmer,
  Loader,
} from 'semantic-ui-react'

import PopupModal from './extras/PopupModal';

import CoverPage from './extras/CoverPage'
import {StopCard} from './extras/StopCard'
import {MortgageFieldGroup, CemaFieldGroup, AssignmentTransferFieldGroup, MersTransferFieldGroup} from './extras/FieldGroup'
import SelectInput from './extras/SelectInput';
import InfoPanel from './extras/InfoPanel';
import Dropzone from 'react-dropzone';


import { ChecklistExport } from './extras/ChecklistExport';
import TextareaAutosize from 'react-textarea-autosize';

const ExtraPhoneLabel = ({phone, icon = 'phone'}) => <a style={{whiteSpace: 'nowrap'}} href={`tel:+${phone.replace(/[^\d]/g,'')}`}><Icon name={icon} />{phone}</a>
const ExtraEmailLabel = ({email}) => <a style={{whiteSpace: 'nowrap'}} href={`mailto:${email}`}><Icon name='at' />{email}</a>
const ExtraUrlLabel = ({url}) => <a style={{whiteSpace: 'nowrap'}} href={url} target='_blank' rel="noopener noreferrer"><Icon name='globe' />{url}</a>

const AttorneyPanel = ({bank, attorney}) => {
  const doesntAssign = attorney?.nickname === "Doesn't Assign"
  return (
    <Card className='attorney-info-container'>
      {doesntAssign ? (
         <Card.Content>
         <Card.Header textAlign='center' className='attorney-name' style={{color:'#db2828'}}>{attorney?.name}</Card.Header>
         </Card.Content>
      ) : 
      <>
      <Card.Content>
        <Card.Header className='attorney-name'>{attorney?.name}</Card.Header>
        
        {attorney?.address ? (
        <Card.Description className='attorney-address'>
          {attorney?.address?.split("\n").map((item, index) => {
            return <p key={index}>{item}</p>
          })}
        </Card.Description>
        ) : null}
      </Card.Content>
      {attorney?.phone || attorney?.fax || attorney?.email ? (
        <Card.Content extra style={{overflowY: 'hidden', overflowX: 'auto'}}>
        {attorney?.phone?.split("\n").map((item, index) => {
            return <><ExtraPhoneLabel key={index} phone={item} /><br/></>
          })}
        {attorney?.fax?.split("\n").map((item, index) => {
          return <><ExtraPhoneLabel key={index} phone={item} icon='fax' /><br/></>
        })}
        {attorney?.email?.split("\n").map((item, index) => {
          return <><ExtraEmailLabel key={index} email={item} /><br/></>
        })}
        {attorney?.url?.split("\n").map((item, index) => {
          return <><ExtraUrlLabel key={index} url={item} /><br/></>
        })}
        </Card.Content>
        ): null }
      </>
      }
    </Card> 
  )
} 

const FollowUpPanel = ({followUps}) => (
  <Segment style={{overflow: 'auto', maxHeight: '250px'}} basic className='no-padding' size='small'>
    {
      followUps?.map((item, index) => {
        return <div key={index}>
          <Label basic className='no-border' size='small' horizontal>{formatDateWithTime(item.date)}</Label>
          <Label style={{float: 'right'}} basic className='no-border' size='tiny' horizontal>{item.user}</Label>
        </div>
      })
    }
  </Segment>
)


function sortByStops(stops) {
  stops.sort(function(a, b) {
    
    var aStop = a.stop
    var bStop = b.stop

    // If the first item has a higher number, move it down
  	// If the first item has a lower number, move it up
    if (aStop > bStop) return 1
    if (aStop < bStop) return -1

    var aTStop = a.transferStop ?? 0
    var bTStop = b.transferStop ?? 0

    var aAStop = a.amendedStop ?? 0
    var bAStop = b.amendedStop ?? 0

    if (aTStop > bTStop) return 1
    if (aTStop < bTStop) return -1

    var aTransfer = aTStop > 0 && aAStop === 0
    var bTransfer = bTStop > 0 && bAStop === 0

    var aAmended = a.isAmended
    var bAmended = b.isAmended

    if (aTransfer && bAmended) return 1
    if (aAmended && bTransfer) return -1

    // Order Mortgage Amended before CEMA Amended
    // if (aAStop > 0 && bAStop > 0) {
    //   var aCema = a.isCema
    //   var bCema = b.isCema

    //   if (aCema && !bCema) return 1
    //   if (!aCema && bCema) return -1
    // }

    if (aAStop > bAStop) return 1
    if (aAStop < bAStop) return -1

    // var aTStop = a.transferStop
    // var bTStop = b.transferStop

    // var aIsTransfer = aTStop !== undefined
    // var bIsTransfer = bTStop !== undefined

    // if (!aIsTransfer && !bIsTransfer) {
    //   return (aStop >= bStop) ? 1 : -1
    // } else {
    //   if (!aIsTransfer && bIsTransfer) {
    //     return (aStop > bStop) ? 1 : -1
    //   } else if (!bIsTransfer && aIsTransfer) {
    //     return (bStop >= aStop) ? 1 : -1
    //   } else if (aIsTransfer && bIsTransfer) {
    //     // console.log("Stops")
    //     // console.log(aStop)
    //     // console.log(bStop)
  
    //     if (aStop === bStop) {
    //       return (aTStop > bTStop) ? 1 : -1
    //     } else {
    //       return (aStop > bStop) ? 1 : -1
    //     }
    //   }
    // }
    return 0
  }) 
}

export class Group extends Component {
  static displayName = Group.name;

  contextRef = createRef()

  state = {
    loading: true,
    cemaGroup: {},
    cemaGroupInfo: {},
    consolidationOptions: {},
    transferOptions: {},
    amendedOptions: {},
    isAddingStop: false,
    stops: [],
    currentStop : {},
    currentStopError : {},
    currentStopType: '',
    checklist: {},
    currentEditedStop: {},
    isEditing: false
  }
  
  setupInitialData(group, groupInfo = null, groupBorrower = null, groupProperty = null) {
    const stops = [...group.mortgages, ...group.amendedMortgages, ...group.cemas, ...group.transfers]

    console.log(stops)
    sortByStops(stops)

    allObjects.splice(0, allObjects.length)
    allObjects.push(...stops)

    stops.filter(_ => !isTransferType(_.type)).forEach((item) => {
      addToMortgagors(item.mortgagor)
    })

    stops.filter(x => isTransferType(x.type)).map(item => {
      //console.log(item)
      const s = getObjectWhereTransferedTo(item.id)
      item.transferFrom = {
        id: s.id,
        type: isTransferType(s.type) ? 2 : 1
      }
      return item
    })

    stops.filter(x => x.isAmended).map(item => {
      const s = getObject(item.amendedFromId)

      item.amendedFrom = {
        id: s.id,
        type: item.type
      }
      return item
    })

    if (groupInfo) {
      this.setState({cemaGroup:group, stops: stops, cemaGroupInfo: groupInfo, groupBorrower, groupProperty})
    } else {
      this.setState({cemaGroup:group, stops: stops})
    }

    return stops
  }

  async intializeDataLists() {
    const result = await makeGetRequest(CemaAPIPathURL+"/getlists")
      if (result) {
        var mortgagees = result.mortgagees
        if (mortgagees) {
          mortgagees.forEach((item) => {
            addToMortgagees(item)
          })
        }

        var banks = result.banks
        if (banks) {
          banks.forEach((item) => {
            addToBanks(item)
          })
        }
      }
  }

  async componentDidMount() {
    resetData()

    const id = this.props.match.params.id

    await this.intializeDataLists()

    if (id === undefined) {
      this.startNewChain()
    } else {
      const build = await this.loadCema(id)

      if (build !== null) {
        if (build.group !== null) {
          const stops = this.setupInitialData(build.group, build.groupInfo, build.groupBorrower, build.groupProperty)
        
          setTimeout(() => {
            this.fetchExtraData()
            if (stops.length > 0) {
              this.fetchSchedule()
              this.endStop()
            } else {
              this.startNewChain()
            }
          }, 50);
        }
      } else {
        this.endStop();
      }
    }
  }
  
  async addObjectToPool(o) {
    const {cemaGroupInfo} = this.state
    o.cemaGroupId = cemaGroupInfo.id

    console.log(o)
    
    let newObject = {}
    switch(o.type) {
      case StopType.Mortgage:
        newObject = await this.addMortgage(cemaGroupInfo.id, o)
      break;
      case StopType.Cema:
        newObject = await this.addCema(cemaGroupInfo.id, o)
      break;
      case StopType.ATransfer:
        newObject = await this.addATransfer(cemaGroupInfo.id, o)
      break;
      case StopType.MTransfer:
        newObject = await this.addMTransfer(cemaGroupInfo.id, o)
      break;
      default:
    }

    if (isTransferType(newObject.type)) {
      addToMortgagees(newObject.assignee)
    } else {
      addToMortgagors(newObject.mortgagor)
      addToMortgagees(newObject.mortgagee)
    }

    allObjects.push(newObject)

    return newObject
  }

  startNewChain() {
    this.setState({isAddingStop: true, loading: false, currentStopType: StopType.None})
    this.setupMortgage(null, { checked : true })
  }

  endStop() {
    this.setState({loading:false, isEditing: false, isAddingStop: false, currentStop: {}, currentStopError: {}, currentEditedStop: {}, currentStopType: StopType.None})
  }

  createStop(stop, stopError, type, cb = null) {
    this.setState({currentStop: stop, currentStopError: stopError, currentStopType: type}, function () {
      if (cb) {
        cb()
      }
    })
  }

  clearStop() {
    this.setState({currentStop: {}, currentStopError: {}, currentStopType: StopType.None})
  }

  startNextStop(event) {
    event.preventDefault()
    this.setState({isAddingStop: true, consolidationOptions: this.fetchOptions(), transferOptions: this.fetchTransferOptions(), amendedOptions: this.fetchAmendedOptions(), currentStopType: StopType.None})
  }

  async addStop(stop) {
    const {stops} = this.state

    stop = await this.addObjectToPool(stop)

    stops.push(stop)

    sortByStops(stops)

    this.setState({stops: stops})
  }

  fetchStopByStop(stop, transferStop = null) {
    return this.state.stops.find(s => s.stop === stop && stop.transferStop === transferStop)
  }

  fetchStop(stopIndex) {
    if (stopIndex > this.state.stops.length) {
      return null
    }
    return this.state.stops[stopIndex]
  }

  setupMortgage = (e, {checked, isSubLien = false, isAmended = false}) => {
    if (checked) {
      const {currentStop, currentStopType} = this.state

      let newStop = isTransferType(currentStopType) || currentStopType === StopType.None ? createNew(mortgage(StopType.Mortgage)) : currentStop
      newStop.type = StopType.Mortgage
      // if (currentStop && isCemaType(currentStop.type)) {
      //   newStop.mortgagor = currentStop.mortgagor
      //   newStop.mortgagee = currentStop.mortgagee
      //   newStop.amount = currentStop.amount
      //   newStop.date = currentStop.date
      //   newStop.isMers = currentStop.isMers
      //   newStop.isCopy = currentStop.isCopy
      // }
      newStop.isSubLien = isSubLien
      newStop.isAmended = isAmended
      newStop.isNoNewMoney = false

      this.createStop(newStop, createNew(mortgageError), StopType.Mortgage)
    } else {
      this.clearStop()
    }
  }

  setupCema = (e, {checked}) => {
    if (checked) {
      const {currentStop, currentStopType} = this.state

      let newStop = isTransferType(currentStopType) || currentStopType === StopType.None ? createNew(mortgage(StopType.Cema)) : currentStop
      newStop.type = StopType.Cema

      // Set Defaults
      newStop.isAssignmentCopy = true
      newStop.isCEMACopy = true
      newStop.consolidatedAmount = 0
      newStop.cemaDate = null

      // Remove Mortgage stuff
      newStop.isSubLien = false
      newStop.isAmended = false
      
      this.createStop(newStop, createNew(cemaError), StopType.Cema)
    } else {
      this.clearStop()
    }
  }

  updateCema(stops, noMoney) {
    const {currentStop, currentStopType} = this.state    

    const create = !isCemaType(currentStop.type) && ((stops === null && noMoney) || (stops.length === 1))
    //const remove = (stops === null && isCemaType(currentStop.type) && currentStop.consolidationFrom.length === 0 && !noMoney) || (stops != null && stops.length === 0 && !currentStop.isNoNewMoney)
    const add = isCemaType(currentStop.type) && stops != null
    const modify = stops === null && isCemaType(currentStop.type)

    if (create) {
      let newStop = isTransferType(currentStopType) || currentStopType === StopType.None ? createNew(mortgage(StopType.Cema)) : currentStop
      newStop.type = StopType.Cema

      // Set Defaults
      newStop.isAssignmentCopy = true
      newStop.isCEMACopy = true
      newStop.consolidatedAmount = 0
      newStop.cemaDate = null

      // Remove Mortgage stuff
      newStop.isSubLien = false
      newStop.isAmended = false
      // if (currentStop && isMortgageType(currentStop.type)) {
      //   newStop.mortgagor = currentStop.mortgagor
      //   newStop.mortgagee = currentStop.mortgagee
      //   newStop.amount = currentStop.amount
      //   newStop.date = currentStop.date
      //   newStop.isMers = currentStop.isMers
      //   newStop.isCopy = currentStop.isCopy
      // }

      if (stops != null) {
        newStop.consolidationFrom = [stops[0]]
      } 
      
      newStop.isNoNewMoney = noMoney

      this.createStop(newStop, createNew(cemaError), StopType.Cema)

      return
    }
    
    if (add) {
      let cStops = []
      stops.forEach((objectId, index) => {
        cStops.push(objectId)
      })

      currentStop.consolidationFrom = cStops

      this.setState({currentStop: currentStop})

      return
    }

    if (modify) {
      currentStop.isNoNewMoney = noMoney
      this.setState({currentStop: currentStop})
      
      return
    }
    
    // if (remove) {
    //   this.setupMortgage(null, { checked : true })
    // }
  }

  setSubLien = (e, {checked}) => {
    const {currentStop} = this.state
    if (isCemaType(currentStop.type)) {
      this.setupMortgage(null, { checked : true, isSubLien: true })
    } else {
      if (!checked) {
        this.setupCema(null, {checked: true})
      } else {
        currentStop.isSubLien = checked
        currentStop.isAmended = false
        this.setState({currentStop: currentStop})
      }
    }
  }

  setNoNewMoney = (e, {checked}) => {
    this.updateCema(null, checked)   
  }

  setAmended = (e, {checked}) => {
    const {currentStop} = this.state
    if (isCemaType(currentStop.type)) {
      this.setupMortgage(null, { checked : true, isAmended: true })
    } else {
      if (!checked) {
        this.setupCema(null, {checked: true})
      } else {
        this.setState({currentStop: currentStop})
      }
    }
  }

  addAmendedStop = (e, {value}) => {
    const {currentStop} = this.state

    if (value.length === 0) {
      currentStop.amendedFrom = {}
    } else {
      const parts = value.split('.')

      var id = parseInt(parts[1])

      var amended = getObject(id)

      currentStop.mortgagor = amended.mortgagor
      currentStop.mortgagee = amended.mortgagee
      currentStop.isMers = amended.isMers
      currentStop.stop = amended.stop

      if (isCemaType(parts[0])) {
        currentStop.amount = amended.consolidatedAmount
        currentStop.date = amended.cemaDate
        currentStop.recordedDate = amended.cemaRecordedDate
        currentStop.crfn = amended.cemaCRFN
        currentStop.isCopy = amended.isCEMACopy
      } else {
        currentStop.amount = amended.amount
        currentStop.date = amended.date
        currentStop.recordedDate = amended.recordedDate
        currentStop.crfn = amended.crfn
        currentStop.isCopy = amended.isCopy
      }
    
      currentStop.amendedFrom = { id : id, type: parts[0] }
    }

    
    this.setState({currentStop:currentStop})
  }

  setupAssignmentTransfer = (e, {checked}) => {
    if (checked) {
      const {currentStop, currentStopType} = this.state
      let newStop = !isTransferType(currentStopType) || currentStopType === StopType.None ? createNew(transfer(StopType.ATransfer)) : currentStop
      newStop.type = StopType.ATransfer
      newStop.isMers = false
      newStop.isCopy = true

      const _this = this
      this.createStop(newStop, createNew(aTransferError), StopType.ATransfer, function() {
        _this.setDefaultTransfer()
      })      
    } else {
      this.clearStop()
    }
  }

  setupMersTransfer = (e, {checked}) => {
    if (checked) {
      const {currentStop, currentStopType} = this.state
      let newStop = !isTransferType(currentStopType) || currentStopType === StopType.None ? createNew(transfer(StopType.MTransfer)) : currentStop
      newStop.type = StopType.MTransfer
      newStop.isMers = true
      newStop.isCopy = false

      const _this = this
      this.createStop(newStop, createNew(mTransferError), StopType.MTransfer, function() {
        _this.setDefaultTransfer()
      })      
    } else {
      this.clearStop()
    }
  }

  setDefaultTransfer() {
    const {stops, isEditing} = this.state
    if (isEditing) {
      return
    }
    const latestStop = stops[stops.length-1]
    
    const value = ((isTransferType(latestStop.type) ? 2 : 1) + "." + latestStop.id).toString()
    this.addTransferredStop(null, { value : value })
  }

  addConsolidatingStops = (e, {value}) => {
    this.updateCema(value, false)
  }

  addTransferredStop = (e, {value}) => {
    const {currentStop} = this.state

    if (value.length === 0) {
      currentStop.transferFrom = {}
    } else {
      const parts = value.split('.')
    
      currentStop.transferFrom = { id : parseInt(parts[1]), type: parseInt(parts[0]) }
    }

    this.setState({currentStop:currentStop})
  }

  handleChange = (e, {name, value, type, checked} = e.target) => { // Need to use e.target here to play along with the Currency Input
    const cStop = this.state.currentStop
    if (type === 'checkbox') {
      cStop[name] = checked
    } else {
      cStop[name] = value   
    }
    this.setState({ currentStop: cStop })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (!this.hasErrors()) {
      const { currentStop, isEditing } = this.state

      if (!isEditing) {
        this.addStop(currentStop)
      } else {
        this.editStop(currentStop)
      }
      
      this.endStop()
    }
  }

  async editStop(currentStop) {
    const {currentEditedStop} = this.state
    const {cemaGroupInfo} = this.state

    const s = {...currentStop}
    console.log(s)
    let path = ''
    if (isTransferType(currentEditedStop.type)) {
      path = 'edittransfer'
      s.type = isATransferType(currentStop.type) ? 1 : 2
    } else {
      path = 'editmortgage'
      
      console.log("Mortgage")
      s.isCema = isCemaType(currentStop.type)

      s.amount = ConvertCurrencyToNumberString(s.amount)
      s.consolidatedAmount = ConvertCurrencyToNumberString(s.consolidatedAmount)

      s.mortgageTaxPaid = ConvertCurrencyToNumberString(s.mortgageTaxPaid)
    }

    s.stopType = isTransferType(currentStop.type) ? 2 : 1

    if (isATransferType(currentStop.type)) {
      s.type = 1 // ENUM Assignment
    } else if (isMTransferType(currentStop.type)) {
      s.type = 2 // ENUM Assignment
    }

    if (s.stopType === 1 && isTransferType(currentEditedStop.type)) {
      s.isCema = isCemaType(currentStop.type)

      console.log("Mortgage again")
      s.amount = ConvertCurrencyToNumberString(s.amount)
      s.consolidatedAmount = ConvertCurrencyToNumberString(s.consolidatedAmount)

      s.mortgageTaxPaid = ConvertCurrencyToNumberString(s.mortgageTaxPaid)
    }

    s.cemaGroupId = cemaGroupInfo.id

    s.newPosition = JSON.stringify(currentStop.transferFrom) !== JSON.stringify(currentEditedStop.transferFrom)

    console.log(s)

    const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/${path}/${currentEditedStop.id}`, s)

    // For now?
    this.setupInitialData(result)
  }

  onCancelAction = (event) => {
    event.preventDefault()
  
    this.endStop()   
  }

  onEditStop = (index, event) => {
    event.preventDefault()
    const editStop = createNew(this.fetchStop(index))

    const errorObject = this.objectForError(editStop.type)
    const edit = createNew(editStop) // Original

    const tOptions = (edit.stop === 1 && edit.transferStop === undefined) ? {options:[], default:''} : this.fetchTransferOptions(true, edit)
    const cOptions = (edit.stop === 1 && edit.transferStop === undefined) ? {options:[], default:[]} : this.fetchOptions(true, edit) 
    const aOptions = (edit.stop === 1 && edit.transferStop === undefined) ? {options:[], default:[]} : this.fetchAmendedOptions(true, edit) 

    this.setState({isAddingStop: false, isEditing: true, currentStop: editStop, currentEditedStop: edit, currentStopError: errorObject, currentStopType: editStop.isAmended ? StopType.Mortgage : editStop.type, transferOptions: tOptions, amendedOptions: aOptions, consolidationOptions: cOptions })
  }

  onDeleteStop = (index, event) => {
    event.preventDefault()

    const {stops} = this.state
    const {cemaGroupInfo} = this.state

    const currentStop = stops[index]
      if (isTransferType(currentStop.type)) {
        // We just want to backstep each of the following transfers of this Stop
        this.onDelete(cemaGroupInfo.id, currentStop, 'deletetransfer')
      } else if (isMortgageType(currentStop.type) || isCemaType(currentStop.type)) {
        this.onDelete(cemaGroupInfo.id, currentStop, 'deletemortgage')
      }
  }

  async onDelete(groupId, stop, path) {
    const result = await makeDeleteRequest(`${BuilderPathURL}/${groupId}/${path}/${stop.id}`)

    // For now?
    this.setupInitialData(result)
  }

  checkAndSetOverride(check, field, override) {
    if (!check) {
      // Good to go
      return {
        field: false,
        override: false
      }
    }
    if (check && !override) {
      if (field === true) {
        field = false
        override = true
      } else {
        field = true     
      }
    }
    return {
      field: field,
      override: override
    }
  }

  objectForError(type) {
    switch(type) {
      case StopType.Mortgage:
        return createNew(mortgageError)
      case StopType.Cema:
        return createNew(cemaError)
      case StopType.ATransfer:
        return createNew(aTransferError)
      case StopType.MTransfer:
        return createNew(mTransferError)
      default:
        return null
    }
  }

  hasErrors() {
    const {currentStop, currentStopError, stops, isEditing} = this.state

    
    let tmpErrorObject = {}
    if (isMortgageType(currentStop.type)) {
      
      currentStopError.mortgagor = currentStop.mortgagor.trim().length === 0
      currentStopError.mortgagee = currentStop.mortgagee.trim().length === 0

      const amountCheck = this.checkAndSetOverride(currentStop.amount === 0, currentStopError.amount, currentStopError.amountOverride)
      currentStopError.amount = amountCheck.field
      currentStopError.amountOverride = amountCheck.override

      const dateCheck = this.checkAndSetOverride(currentStop.date === null, currentStopError.date, currentStopError.dateOverride)
      currentStopError.date = dateCheck.field
      currentStopError.dateOverride = dateCheck.override

      const shouldBeCema = !isEditing && !currentStop.isAmended && !currentStop.isSubLien && stops.length > 0
      currentStopError.isCema = shouldBeCema || (isEditing && !currentStop.isSubLien && currentStop.stop !== 1)

      currentStopError.isAmended = currentStop.isAmended && isEmpty(currentStop.amendedFrom)

      tmpErrorObject = createNew(currentStopError)

      delete tmpErrorObject['amountOverride']
      delete tmpErrorObject['dateOverride']

//      ['amountOverride', 'dateOverride'].forEach(e => delete tmpErrorObject[e]); // Not working

    } else if (isCemaType(currentStop.type)) {

      currentStopError.mortgagor = currentStop.mortgagor.trim().length === 0
      currentStopError.mortgagee = currentStop.mortgagee.trim().length === 0

      //currentStopError.amount = currentStop.amount == 0 && !currentStop.isNoNewMoney
      const amountCheck = this.checkAndSetOverride(currentStop.amount === 0 && !currentStop.isNoNewMoney, currentStopError.amount, currentStopError.amountOverride)
      currentStopError.amount = amountCheck.field
      currentStopError.amountOverride = amountCheck.override

      //currentStopError.consolidatedAmount = currentStop.consolidatedAmount == 0
      const consolidatedAmountCheck = this.checkAndSetOverride(currentStop.consolidatedAmount === 0, currentStopError.consolidatedAmount, currentStopError.consolidatedAmountOverride)
      currentStopError.consolidatedAmount = consolidatedAmountCheck.field
      currentStopError.consolidatedAmountOverride = consolidatedAmountCheck.override

      //currentStopError.newDate = (currentStopError.date == false ? currentStop.date.length == 0 : false) && !currentStop.isNoNewMoney
      const dateCheck = this.checkAndSetOverride(currentStop.date === null && !currentStop.isNoNewMoney, currentStopError.newDate, currentStopError.newDateOverride)
      currentStopError.newDate = dateCheck.field
      currentStopError.newDateOverride = dateCheck.override

      //currentStopError.cemaDate = currentStopError.cemaDate == false ? currentStop.cemaDate.length == 0 : false
      const cemaDateCheck = this.checkAndSetOverride(currentStop.cemaDate === null, currentStopError.cemaDate, currentStopError.cemaDateOverride)
      currentStopError.cemaDate = cemaDateCheck.field
      currentStopError.cemaDateOverride = cemaDateCheck.override

      currentStopError.consolidationFrom = currentStop.consolidationFrom === undefined || currentStop.consolidationFrom.length === 0

      tmpErrorObject = createNew(currentStopError)

      delete tmpErrorObject['amountOverride']
      delete tmpErrorObject['consolidatedAmountOverride']
      delete tmpErrorObject['newDateOverride']
      delete tmpErrorObject['cemaDateOverride']

      //['amountOverride', 'consolidatedAmountOverride', 'assignmentDateOverride', 'newDateOverride', 'cemaDateOVerride' ].forEach(e => delete tmpErrorObject[e]);

    } else if (isATransferType(currentStop.type)) {

      currentStopError.assignee = currentStop.assignee.trim().length === 0

      const dateCheck = this.checkAndSetOverride(currentStop.date === null, currentStopError.date, currentStopError.dateOverride)
      currentStopError.date = dateCheck.field
      currentStopError.dateOverride = dateCheck.override

      currentStopError.transferFrom = isEmpty(currentStop.transferFrom)

      tmpErrorObject = createNew(currentStopError)

      delete tmpErrorObject['dateOverride']
      //['dateOverride'].forEach(e => delete tmpErrorObject[e]);
      
    } else if (isMTransferType(currentStop.type)) {

      currentStopError.assignee = currentStop.assignee.trim().length === 0

      const dateCheck = this.checkAndSetOverride(currentStop.date === null, currentStopError.date, currentStopError.dateOverride)
      currentStopError.date = dateCheck.field
      currentStopError.dateOverride = dateCheck.override

      currentStopError.transferFrom = isEmpty(currentStop.transferFrom)

      tmpErrorObject = createNew(currentStopError)

      delete tmpErrorObject['dateOverride']
      //['dateOverride'].forEach(e => delete tmpErrorObject[e]);

    }

    //console.log(currentStopError)
    this.setState({currentStopError: currentStopError})

    return Object.values(tmpErrorObject).some(element => element === true)
  }

  fetchAmendedOptions(isEditing = false, currentEditedStop = null) {
    const stops = [...this.state.stops]

    stops.reverse()

    let options = []
    let defaultSelected = []

    stops.forEach((stop, index) => {
      if (stop.isAmended || (isEditing && stop.id === currentEditedStop.id) || (isEditing && stop.stop > currentEditedStop.stop)) {
      } else {
        var isMortgage = isMortgageType(stop.type)
        var isCema = isCemaType(stop.type)
        if (isMortgage || isCema) {
          let curStop = stop.stop
  
          let aIndex = (stops.length-1) - index
  
          // let doesExist = false
          // let re = new RegExp("\\b(" + curStop + ")\\b")
          
          // currentStopLabels.forEach((t, index) => {
          //   if (t.match(re)) {
          //     doesExist = true
          //     return;
          //   }
          // })

          const isDefault = isEditing && !isEmpty(currentEditedStop.amendedFrom) && currentEditedStop.amendedFrom.type === stop.type && currentEditedStop.amendedFrom.id === stop.id


          if (isMortgage) {
            options.push({
              key: (aIndex+1).toString(),
              text: "Stop " + curStop,
              value: `Mortgage.${stop.id}`,
              selected: isDefault
            })
    
          } else {

            options.push({
              key: (aIndex+1).toString()+'a',
              text: "Stop " + curStop + " Cons.",
              value: `CEMA.${stop.id}`,
              selected: isDefault
            })

            if (!stop.isNoNewMoney) {
              options.push({
                key: (aIndex+1).toString(),
                text: "Stop " + curStop,
                value: `Mortgage.${stop.id}`,
                selected: isDefault
              })
            }
    
          } 
  

          if (isDefault) {
            defaultSelected.push(stop.id)
          }
        }
      }
    })

    options.reverse()

    return {
      options: options,
      default: defaultSelected
    }
  }

  fetchOptions(isEditing = false, currentEditedStop = null) {
    const stops = [...this.state.stops]

    stops.reverse()

    let currentStopLabels = []
    let options = []
    let defaultSelected = []

    //let oCount = 1
    stops.forEach((stop, index) => {
      if ((isEditing && stop.id === currentEditedStop.id) || (isEditing && stop.stop > currentEditedStop.stop)) {
      } else {
        if (isMortgageType(stop.type) || isCemaType(stop.type)) {
          let curStop = stop.stop
  
          let aIndex = (stops.length-1) - index
  
          let doesExist = false
          let re = new RegExp("\\b(" + curStop + ")\\b")
          
          currentStopLabels.forEach((t, index) => {
            if (t.match(re)) {
              doesExist = true
              return;
            }
          })

          const isDefault = isEditing && !currentEditedStop.isAmended &&  isCemaType(currentEditedStop.type) && currentEditedStop.consolidationFrom.includes(stop.id)
  
          if (!doesExist) {
            
            let text = formattedStopLabel(getConsolidatedStopLabel(stop))
  
            options.push({
              key: (aIndex+1).toString(),
              text: "Stop " + text,
              value: stop.id,
              selected: isDefault
            })
    
            currentStopLabels.push(text)

            if (isDefault) {
              defaultSelected.push(stop.id)
            }
          }
        }
      }
    })

    options.reverse()

    return {
      options: options,
      default: defaultSelected
    }
  }

  fetchTransferOptions(isEditing = false, currentEditedStop = null) {
    const {stops} = this.state
    
    let latestIndex = 0
    let options = []
    let defaultSelected = ''

    stops.forEach((stop, index) => {
      if (isEditing && 
        ((stop.id === currentEditedStop.id && stop.type === currentEditedStop.type) || 
        (!isTransferType(currentEditedStop.type) && isTransferType(stop.type) && currentEditedStop.stop === stop.stop) ||
        ((!isTransferType(currentEditedStop.type) && currentEditedStop.stop === stop.stop && currentEditedStop.transferStop === stop.transferStop)))) {
      } else {
        
        let newIndex = stop.stop

        if (!isEditing) {
          if (latestIndex !== 0) {
            if (latestIndex === newIndex) {
              options.pop()
            }
          }
        }

        latestIndex = newIndex

        let isDefault = false
        if (isEditing) {
          if (isTransferType(currentEditedStop.type)) {
            const transferFrom = currentEditedStop.transferFrom === null || isEmpty(currentEditedStop.transferFrom) ? getObjectWhereTransferedTo(currentEditedStop.id) : currentEditedStop.transferFrom//getObject(transferFrom.id, transferFrom.type === 2)
            isDefault = stop.id === transferFrom.id
          }
        }

        const value = ((isTransferType(stop.type) ? 2 : 1) + "." + stop.id).toString()
        
        
        options.push({
          key: (index+1).toString(),
          text: "Stop " + stop.stop + (stop.transferStop !== undefined ? getLetterFromIndex(stop.transferStop) : ''),
          value: value,
          selected: isDefault
        })

        if (isDefault) {
          defaultSelected = value
        }
      }
    })

    return {
      options: options,
      default: defaultSelected
    }
  }

  fetchExtraData = async () => {
    const {cemaGroupInfo} = this.state

    const result = await makeGetRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/getextradata`)

    if (result)
      this.setState({followUps: result.followUps, documents: result.documents})
  }

  fetchSchedule = async () => {
    const {cemaGroupInfo} = this.state

    const result = await makeGetRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/getchecklist`)

    if (result) 
      this.setState({checklist: result})
  }

  publishSchedule = async (event) => {
    event.preventDefault()

    const {cemaGroupInfo} = this.state

    const result = await makePostRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/publishchecklist`)

    this.setState({checklist: result})
  }

  uploadSchedule = async (files) => {  
    if (files.length === 0) {
      return
    }
    this.setState({uploadingSchedule: true})
    const {cemaGroupInfo} = this.state

    const chain = await uploadFiles(`${BuilderPathURL}/${cemaGroupInfo.id}/uploadschedule`, files)

    if (chain && chain['level'] === undefined) {
      this.setState({uploadingSchedule: false}, () => {
        this.setupInitialData(chain)

        this.endStop()
      })
    }
  }

  async loadCema(groupId) {
    const result = await makeGetRequest(`${BuilderPathURL}/${groupId}`)
    
    return result
  }

  async closeCema() {
    const {cemaGroupInfo} = this.state

    const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/close`)
    if (result) {
      if (result.closed) {
          cemaGroupInfo.isClosed = true
          this.setState({cemaGroupInfo})
      } else {
          this.setState({cemaGroupInfo: result.groupInfo, checklist: result.checklist, followUps: []}) 
      }
    }
  }

  handleFollowUp = () => {
    const {cemaGroupInfo, followUps} = this.state

    this.setState({followingUp: true})

    setTimeout(async () => {
      const result = await makePostRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/addfollowup`, {cemaGroupId: cemaGroupInfo.id})
  
      if (result) {
        followUps.unshift(result)
      }
      this.setState({followUps, followingUp: false})
    }, 250);
  }

  handleCemaArchive = async () => {
    const {cemaGroupInfo} = this.state

    const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/archive`)
    if (result !== null) {
      cemaGroupInfo.isArchived = result
      this.setState({cemaGroupInfo})
    }
  }

  handleCemaPriority = async () => {
    const {cemaGroupInfo} = this.state

    const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/priority`)
    if (result !== null) {
      cemaGroupInfo.isPriority = result
      this.setState({cemaGroupInfo})
    }
  }

  handleCemaImmediate = async () => {
    const {cemaGroupInfo} = this.state

    const result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/immediate`)
    if (result !== null) {
      cemaGroupInfo.isImmediate = result
      this.setState({cemaGroupInfo})
    }
  }

  handleCemaClose = () => {
    const {cemaGroupInfo} = this.state

    const header = `${cemaGroupInfo.isClosed ? 'Opening' : 'Closing'} Confirmation`
    const text = cemaGroupInfo.isClosed ? 'Please confirm that you would like to open this CEMA. All previous CEMA information will be cleared.' : 'Please confirm that you would like to close this CEMA.'

    this.setState({
      alertHeader: header,
      alertContent: text, 
      alertConfirm: () => {
        this.setState({shouldAlert: false}, () => {
          this.closeCema()
        })
      },
      alertCancel: () => {
        this.setState({shouldAlert: false}, () => {
          console.log("Was Canceled")
        })
      },
      shouldAlert: true
      })
  }

  async addMortgage(groupId, mortgage) {
    delete mortgage.id
    mortgage.amount = ConvertCurrencyToNumberString(mortgage.amount)
    mortgage.mortgageTaxPaid = ConvertCurrencyToNumberString(mortgage.mortgageTaxPaid)
    mortgage.isCema = false

    const result = await makePostRequest(`${BuilderPathURL}/${groupId}/addmortgage`, mortgage)

    return result
  }

  async addCema(groupId, cema) {
    delete cema.id
    cema.amount = ConvertCurrencyToNumberString(cema.amount)
    cema.consolidatedAmount = ConvertCurrencyToNumberString(cema.consolidatedAmount)

    cema.mortgageTaxPaid = ConvertCurrencyToNumberString(cema.mortgageTaxPaid)
    cema.isCema = true

    const result = await makePostRequest(`${BuilderPathURL}/${groupId}/addmortgage`, cema)

    return result
  }

  async addATransfer(groupId, transfer) {
    delete transfer.id

    transfer.type = 1 // ENUM Assignment

    const result = await makePostRequest(`${BuilderPathURL}/${groupId}/addtransfer`, transfer)

    return result
  }

  async addMTransfer(groupId, transfer) {
    delete transfer.id

    transfer.type = 2 // ENUM MERS

    const result = await makePostRequest(`${BuilderPathURL}/${groupId}/addtransfer`, transfer)

    return result
  }

  updateDocumentName = async (e, {name, value} = e.target) => {
    const {documents, cemaGroupInfo} = this.state

    const d = documents.find(_ => _.id.toString() === name)
    
    if (d) {
      if (value !== d.documentName) {

        var result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/updatefilename/${d.id}`, value)

        if (result) {
          d.documentName = value

          this.setState({documents})
        }
      }
    }
  }

  downloadFile = (fileId) => {
    const {cemaGroupInfo} = this.state

    openFile(`${BuilderPathURL}/${cemaGroupInfo.id}/downloadfile/${fileId}`)
  }

  deleteFile = async (fileId) => {
    const {cemaGroupInfo, documents} = this.state

    const deletedDocument = await makeDeleteRequest(`${BuilderPathURL}/${cemaGroupInfo.id}/deletefile/${fileId}`)

    let doc = documents.find(_ => _.id === deletedDocument.id)
    let index = documents.indexOf(doc)
    if (index >= 0) {
      documents.splice(index, 1)
    }

    this.setState({documents})
  }

  addNewDocuments = async (files) => {
    if (files.length === 0) {
      return
    }
    const {cemaGroupInfo} = this.state

    const newDocs = await uploadFiles(`${BuilderPathURL}/${cemaGroupInfo.id}/uploadfiles`, files)

    // Need error checking
    if (Array.isArray(newDocs)) {
      const {documents} = this.state
    
      documents.push(...newDocs)

      this.setState({documents})
    }
  }

  exportChecklist = () => {
    const {checklist, cemaGroupInfo, groupBorrower, groupProperty} = this.state

    ChecklistExport(cemaGroupInfo, groupProperty, groupBorrower, checklist)
  }

  updateInfo = (info) => {
    this.setState({cemaGroupInfo: info})
  }

  updateBorrower = (borrower) => {
    this.setState({groupBorrower: borrower})
  }

  updateProperty = (property) => {
    this.setState({groupProperty: property})
  }

  async handleNotesChange(event) {
    const {cemaGroupInfo} = this.state
    const val = event.target.value


    if (cemaGroupInfo.groupNotes !== val) {
      cemaGroupInfo.groupNotes = val
      
      var result = await makePutRequest(`${BuilderPathURL}/${cemaGroupInfo.id}`, cemaGroupInfo)
    
      if (result) {
        this.setState({cemaGroupInfo: cemaGroupInfo})
      }
    }
  }

  renderBuilder = () => {
    const { cemaGroupInfo, groupBorrower, groupProperty, checklist, currentStop, currentStopError, currentStopType, stops, isAddingStop, consolidationOptions, transferOptions, amendedOptions, isEditing, currentEditedStop } = this.state

    return (
      <div className='Builder'>
        
        <InfoPanel info={cemaGroupInfo} borrower={groupBorrower} property={groupProperty} updateInfo={this.updateInfo.bind(this)} updateBorrower={this.updateBorrower.bind(this)} updateProperty={this.updateProperty.bind(this)} />

        <Divider/>

        <CoverPage closed={cemaGroupInfo.isClosed} cemaGroupId={cemaGroupInfo.id} checklist={checklist} />
        
        
        { currentStopError.isCema || currentStopError.consolidationFrom ? (
          <ErrorMessage message="You need to select a stop to consolidate."/>
        ) : currentStopError.transferFrom ? (
          <ErrorMessage message="You need to select a stop to transfer."/>
        ) : currentStopError.amendedFrom ? (
          <ErrorMessage message="You need to select a stop to amend."/>
        ) : null } 

        <Form onSubmit={this.handleSubmit.bind(this)} className='cema-form'>
          <Grid centered>
            { stops.length > 0 && !isAddingStop && !isEditing ? (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Segment floated='left' basic>
                    <Button content='Add New Stop' onClick={this.startNextStop.bind(this)} primary/>
                    <Button content='Publish' onClick={this.publishSchedule.bind(this)} positive/>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            ) : stops.length === 0 || isAddingStop || isEditing ? (
              <Grid.Row columns={2}>
                { (stops.length > 0) && (isAddingStop || ((isEditing && currentEditedStop.stop !== 1) || currentEditedStop.transferStop !== undefined))  ? (
                <Grid.Column floated='left' width={4}>
                  <Form.Group grouped className="grouped-container">
                    <Form.Field
                    control={Checkbox}
                    label='New Mortgage'
                    onChange={stops.length === 0 ? this.setupMortgage.bind(this) : this.setupCema.bind(this)}
                    checked={isMortgageType(currentStopType) || isCemaType(currentStopType)}
                    //disabled={isTransferType(currentStopType)}
                    />
                    <Form.Group grouped>
                      <Form.Field
                      control={Checkbox}
                      label='Subordinate Lien'
                      onChange={this.setSubLien.bind(this)}
                      checked={currentStop.isSubLien === true}
                      disabled={isTransferType(currentStopType) || currentStopType === StopType.None}
                      />
  
                      <Form.Field
                      control={Checkbox}
                      label='No New Money'
                      onChange={this.setNoNewMoney.bind(this)}
                      checked={currentStop.isNoNewMoney === true}
                      disabled={isTransferType(currentStopType) || currentStopType === StopType.None}
                      />

                      <Form.Field
                      control={Checkbox}
                      label='Amended'
                      onChange={this.setAmended.bind(this)}
                      checked={currentStop.isAmended === true}
                      disabled={isTransferType(currentStopType) || currentStopType === StopType.None}
                      />
  
                    </Form.Group>
                    </Form.Group>
                    <Divider />
                    <Form.Group grouped className="grouped-container">
                      <Form.Field
                      control={Checkbox}
                      label='Assignment Transfer'
                      onChange={this.setupAssignmentTransfer.bind(this)}
                      checked={isATransferType(currentStopType)}
                      //disabled={currentStopType == StopType.Mortgage || currentStopType == StopType.Cema}
                      />
                      <Form.Field
                      control={Checkbox}
                      label='MERS Transfer'
                      onChange={this.setupMersTransfer.bind(this)}
                      checked={isMTransferType(currentStopType)}
                      //disabled={currentStopType == StopType.Mortgage || currentStopType == StopType.Cema}
                      />

                      <Form.Group grouped>
                        <Form.Field
                        control={Select}
                        label='Transferred From'
                        options={transferOptions.options}
                        placeholder='Stop'
                        width='3'
                        //defaultValue={transferOptions.default}
                        value={isTransferType(currentStopType) ? (!isEmpty(currentStop.transferFrom) ? (currentStop.transferFrom.type + '.' + currentStop.transferFrom.id) : '') : '' }
                        clearable
                        onChange={this.addTransferredStop.bind(this)}
                        disabled={(!isATransferType(currentStopType) && !isMTransferType(currentStopType)) || stops.length === 0}
                        />
                      </Form.Group>
                    </Form.Group>
                  
                </Grid.Column>  
                ) : null }  
              
                <Grid.Column floated={stops.length > 0 && !(isEditing && currentEditedStop.stop === 1 && currentEditedStop.transferStop === undefined) ? 'left' : ''} verticalAlign='middle' width={9}>

                  { (((isMortgageType(currentStopType) && !currentStop.isSubLien && currentStop.stop !== 1 || (currentStop.stop === 1 && currentStop.isAmended)) || isCemaType(currentStopType)) && stops.length > 0) ? (
                    <>
                    {currentStop.isAmended ? (
                      <Form.Group grouped>
                        <Form.Field className='cema-form-field no-error' error={currentStopError.isAmended} >
                          <SelectInput
                          label='Amending'
                          multiple={false}
                          options={amendedOptions.options}
                          placeholder='Stop'
                          //defaultValue={transferOptions.default}
                          value={isMortgageType(currentStopType) ? (!isEmpty(currentStop.amendedFrom) ? (currentStop.amendedFrom.type + '.' + currentStop.amendedFrom.id) : '') : '' }
                          onChange={this.addAmendedStop.bind(this)}
                          disabled={isTransferType(currentStopType) || currentStopType === StopType.None} />
                        </Form.Field>
                    </Form.Group>
                    ) : (
                      <Form.Group grouped>
                        <Form.Field className='cema-form-field no-error' error={currentStopError.isCema || currentStopError.consolidationFrom}>
                          <SelectInput
                          label='Consolidating'
                          multiple={true}
                          options={consolidationOptions.options}
                          placeholder='Stop'
                          //defaultValue={consolidationOptions.default}
                          value={isCemaType(currentStopType) ? currentStop.consolidationFrom : []}
                          onChange={this.addConsolidatingStops.bind(this)}
                          disabled={isTransferType(currentStopType) || currentStopType === StopType.None}/>
                        </Form.Field>
                      </Form.Group>
                      ) 
                      }
                    </>
                  ) : null }
         
                  { isMortgageType(currentStopType) ? (
                  <MortgageFieldGroup mortgage={currentStop} mortgageError={currentStopError} onChange={this.handleChange.bind(this)}/>
                ) : isCemaType(currentStopType) ? (
                  <CemaFieldGroup cema={currentStop} cemaError={currentStopError} onChange={this.handleChange.bind(this)}/>
                ) : isATransferType(currentStopType) ? (
                  <AssignmentTransferFieldGroup aTransfer={currentStop} aTransferError={currentStopError} onChange={this.handleChange.bind(this)}/>
                ) : isMTransferType(currentStopType) ? (
                  <MersTransferFieldGroup mTransfer={currentStop} mTransferError={currentStopError} onChange={this.handleChange.bind(this)}/>
                ) : null}

              {currentStopType !== StopType.None ? (
                <Segment basic floated='right'>
                  <Form.Group>
                  { stops.length > 0 ? (
                    <Form.Field>
                        <Button type='button' content='Cancel' onClick={this.onCancelAction.bind(this)} negative/>
                    </Form.Field>
                  ) : null}
                  <Form.Field>
                    <Button content={isEditing ? 'Save' : 'Submit'} primary/>
                  </Form.Field>
                </Form.Group>
                </Segment>
              ) : null }
              </Grid.Column>
              </Grid.Row>
            ) : null }
            </Grid>
        </Form>

        {currentStopType === StopType.None && isAddingStop && stops.length > 0 ? (
          <Segment floated='left' basic><Button type='button' className='cema-form-cancel' content='Cancel' onClick={this.onCancelAction.bind(this)} negative/></Segment>
        ) : null}
        
        <Divider clearing/>

        { stops.length > 0 ? (
          <section>
            <Grid className='card-container' stretched>
              <Grid.Row>
                { stops.map((stop, index) => {
                  return (
                  <Grid.Column key={index} width={4}>
                    <StopCard stopInfo={stop} onEdit={this.onEditStop.bind(this, index)} onDelete={this.onDeleteStop.bind(this, index)} isEditing={isEditing}
                     isCurrentEditing={isEditing && currentEditedStop.stop === stop.stop 
                      && currentEditedStop.transferStop === stop.transferStop 
                      && currentEditedStop.isAmended === stop.isAmended
                      && currentEditedStop.type === stop.type
                      && currentEditedStop.id === stop.id
                    } 
                     isFirst={index===0}/>
                  </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          <Divider/>
        </section>
        ) : null }

      </div>
    )
  }

  renderDocumentList = () => {
    const {documents} = this.state
    return (
      <Table size='small' compact='very' basic='very'>
        <Table.Body>
          {documents?.map((document) => {
              return (
                <Table.Row key={document.id}>
                  <Table.Cell collapsing>
                    <Icon title={document.user} link fitted name='file pdf' color='red' onClick={(e) => {e.preventDefault(); this.downloadFile(document.id)}} />
                  </Table.Cell> 
                  <Table.Cell>
                  <Input fluid size='small' transparent name={document.id} defaultValue={document.documentName} onBlur={this.updateDocumentName} />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Label size='mini'>{document.dateCreated}</Label>
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Icon link fitted name='trash' color='red' onClick={(e) => {e.preventDefault(); this.deleteFile(document.id)}} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    )
  }

  renderBuilderContainer = () => {
    const {cemaGroup, checklist, cemaGroupInfo, followUps, followingUp, shouldAlert, alertHeader, alertContent, alertConfirm, alertCancel} = this.state
    const {bank, cemaAttorney} = cemaGroupInfo ?? null
    return (
      <>
      { shouldAlert ? (
        <PopupModal header={alertHeader} content={alertContent} onConfirm={alertConfirm} onCancel={alertCancel} />
      ) : null }
      <Ref innerRef={this.contextRef}>
            <Segment basic>

            {this.renderBuilder()}

            
              <Rail position='left'>
                <Sticky context={this.contextRef} offset={75}>
                  <Segment.Group horizontal className='no-padding-v no-border'>
                  <Segment basic className='no-padding'>
                      <em>Closed</em>
                      <Checkbox toggle style={{verticalAlign: 'middle', marginLeft: '1em', marginRight: '1em'}} checked={cemaGroupInfo.isClosed} onChange={this.handleCemaClose.bind(this)} />
                      <Button basic className='no-border' circular icon='archive' color={cemaGroupInfo.isArchived ? 'green' : null} onClick={this.handleCemaArchive.bind(this)} />
                  </Segment>
                  <Segment basic className='no-border no-padding'>
                    <Button basic className='no-border' circular icon='exclamation' floated='right' color={cemaGroupInfo.isImmediate ? 'red' : null} onClick={this.handleCemaImmediate.bind(this)} />
                    <Button basic className='no-border' circular icon='star' floated='right' color={cemaGroupInfo.isPriority ? 'yellow' : null} onClick={this.handleCemaPriority.bind(this)} />
                  </Segment>
                  </Segment.Group>
                  {cemaGroup?.mortgages?.length === 0 && cemaGroup?.cemas?.length === 0 ? (
                    <Segment basic className='no-padding'>
                    
                    <Dropzone
                      accept='.pdf'
                      onDrop={acceptedFiles => {
                        this.uploadSchedule(acceptedFiles)
                      // acceptedFiles.forEach((file) => {
                      //   console.log(file)
  
                      //   const reader = new FileReader()
  
                      //   reader.onabort = () => console.log('file reading was aborted')
                      //   reader.onerror = () => console.log('file reading has failed')
                      //   reader.onload = () => {
                      //     // Do whatever you want with the file contents
                      //       const binaryStr = reader.result
                      //       console.log(binaryStr)
                      //     }
                      //     reader.readAsArrayBuffer(file)
                      // })
                    }}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div style={{cursor: "pointer"}} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                            <Label style={{width: '100%', textAlign: 'center'}} color='green'>Upload Schedule</Label>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    </Segment>
                  ) : null}
                  {cemaAttorney ? (
                    <AttorneyPanel bank={bank} attorney={cemaAttorney} />
                  ) : null }
                  {!isEmpty(checklist) ? (
                  <Segment basic className='no-padding'>
                    <Button size='small' fluid onClick={this.exportChecklist.bind(this)}>Export Checklist</Button>
                  </Segment>
                  ) : null }
                  <Segment basic className='no-padding'>
                    <Form size='small'>
                    <TextareaAutosize key={cemaGroupInfo.groupNotes} minRows={1} onBlur={e => { this.handleNotesChange(e) }} placeholder='Notes...' defaultValue={cemaGroupInfo.groupNotes}/>
                    </Form>
                  </Segment>
                  <Segment basic className='no-padding'>
                    <Button disabled={followingUp || cemaGroupInfo.isClosed} fluid size='small' content='Follow Up' onClick={this.handleFollowUp.bind(this)} />
                    {followUps && followUps.length > 0 ? (
                      <FollowUpPanel followUps={followUps} />
                     ) : null }
                  </Segment>
                </Sticky>
              </Rail>

              <Rail position='right'>
                <Sticky context={this.contextRef} offset={75}>
                  <Dropzone onDrop={acceptedFiles => {
                    this.addNewDocuments(acceptedFiles)
                    // acceptedFiles.forEach((file) => {
                    //   console.log(file)

                    //   const reader = new FileReader()

                    //   reader.onabort = () => console.log('file reading was aborted')
                    //   reader.onerror = () => console.log('file reading has failed')
                    //   reader.onload = () => {
                    //     // Do whatever you want with the file contents
                    //       const binaryStr = reader.result
                    //       console.log(binaryStr)
                    //     }
                    //     reader.readAsArrayBuffer(file)
                    // })
                  }}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div style={{cursor: "pointer"}} {...getRootProps()}>
                          <input {...getInputProps()} />
                          {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                          <Segment basic textAlign='center'>
                            <Label>Drop or Click to Upload Documents</Label>
                          </Segment>
                        </div>
                        <div style={{overflow: 'auto', maxHeight: '80vh' }}>
                          {this.renderDocumentList()}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Sticky>
            </Rail>
            </Segment>
        </Ref>
        </>
    )
  }

  render () {
    const {cemaGroupInfo, cemaGroup, loading, uploadingSchedule} = this.state

    let contents = loading ? RenderLoadingMessage() : isEmpty(cemaGroupInfo) && isEmpty(cemaGroup) ? RenderErrorMessage() : this.renderBuilderContainer()
  
    return (
    <Container className='Group'>
      {contents}
      {uploadingSchedule ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : null}
    </Container>
    );
  }
}