import React from 'react';

import CurrencyInput from './CurrencyInput'
import DateInput from './DateInput'
import DropdownInput from './DropdownInput'
import {Form, Input, Divider} from 'semantic-ui-react'

import {OverrideErrorMessage} from '../../utility/Global'

const FieldInput = ({onChange, label, name, value, width = '7', disabled = false, error, errorMessage = 'Field is required', type = 'text' }) => (
    <Form.Field className='cema-form-field' width={width} error={error}>
      { type === 'number' ? (
        <CurrencyInput label={label} name={name} value={value} onChange={onChange} disabled={disabled} />
      ) : type === 'date' ? (
        <DateInput className='cema-form-input' label={label} name={name} value={value} onBlur={onChange} disabled={disabled} />
      ) : type === 'dropdown' ? (
        <DropdownInput label={label} name={name} value={value} onChange={onChange} disabled={disabled} />
      ) :
      <Input className='cema-form-input' size="small" label={label} name={name} type={type} value={value} onChange={onChange} autoComplete='off' labelPosition='left' disabled={disabled} />
      }
      {error ? (
        <label className='cema-form-input-error'>{errorMessage}</label>
      ) : null }
    </Form.Field>
  )
  
const FieldCheckbox = ({onChange, label, name, width = '6', checked}) => (
    <Form.Checkbox className='cema-form-input cema-form-checkbox' width={width} label={label} name={name} onChange={onChange} checked={checked}/>
)
  
export const MortgageFieldGroup = ({mortgage, mortgageError, onChange}) => (
    <Form.Group grouped>
      <Form.Group inline>
      <FieldInput type='dropdown' width={14} label='Mortgagor' name='mortgagor' value={mortgage.mortgagor} onChange={onChange} error={mortgageError.mortgagor}/>
      {mortgage.isAmended ? (
        <FieldCheckbox width={2} label='Note' name='isAmendedNote' onChange={onChange} checked={mortgage.isAmendedNote}/>
      ) : null}
      </Form.Group>
      <Form.Group inline>
        <FieldInput type='dropdown' width={14} label='Mortgagee' name='mortgagee' value={mortgage.mortgagee} onChange={onChange} error={mortgageError.mortgagee} disabled={mortgage.isAmended && !mortgage.isAmendedNote} />
        <FieldCheckbox width={2} label='MERS' name='isMers' onChange={onChange} checked={mortgage.isMers}/>
      </Form.Group>
      <Form.Group inline>
        <FieldInput label='Amount' name='amount' value={mortgage.amount} type='number' onChange={onChange} error={mortgageError.amount} errorMessage={OverrideErrorMessage}/>
        <FieldInput label='Date' name='date' value={mortgage.date} type='date' onChange={onChange} error={mortgageError.date} errorMessage={OverrideErrorMessage}/>
        <FieldCheckbox width={2} label='Recorded' name='isCopy' onChange={onChange} checked={mortgage.isCopy}/>
      </Form.Group>
      <Form.Group inline className={!mortgage.isCopy ? 'hide-me' : ''}>
        <FieldInput label='Recorded Date' name='recordedDate' value={mortgage.recordedDate} type='date' onChange={onChange}/> 
        <FieldInput label='CRFN#' name='crfn' value={mortgage.crfn} onChange={onChange}/>
      </Form.Group>
      {!mortgage.isAmended ? (
        <Form.Group inline className={!mortgage.isCopy ? 'hide-me' : ''}>
          <FieldInput label='Mtg. Tax Paid' name='mortgageTaxPaid' value={mortgage.mortgageTaxPaid} type='number' onChange={onChange}/>
        </Form.Group>
      ) : null}
    </Form.Group>
  )
  
export const CemaFieldGroup = ({cema, cemaError, onChange}) => (
    <Form.Group grouped>
      <Form.Group inline>
        <FieldInput type='dropdown' width={14} label='Mortgagor' name='mortgagor' value={cema.mortgagor} onChange={onChange} error={cemaError.mortgagor} />
      </Form.Group>
      <Form.Group inline>
        <FieldInput type='dropdown' width={14} label='Mortgagee' name='mortgagee' value={cema.mortgagee} onChange={onChange} error={cemaError.mortgagee} />
        <FieldCheckbox width={2} label='MERS' name='isMers' onChange={onChange} checked={cema.isMers}/>
      </Form.Group>
      { !cema.isNoNewMoney ? (
        [<Form.Group inline>
          <FieldInput label='Gap Amount' name='amount' value={cema.amount} type='number' onChange={onChange} error={cemaError.amount} errorMessage={OverrideErrorMessage}/>
          <FieldInput label='Gap Date' name='date' value={cema.date} type='date' onChange={onChange} error={cemaError.newDate} errorMessage={OverrideErrorMessage}/>
          <FieldCheckbox width={2} label='Recorded' name='isCopy' onChange={onChange} checked={cema.isCopy}/>
        </Form.Group>,
        <Form.Group inline className={!cema.isCopy ? 'hide-me' : ''}>
          <FieldInput label='Recorded Date' name='recordedDate' value={cema.recordedDate} type='date' onChange={onChange}/> 
          <FieldInput label='CRFN#' name='crfn' value={cema.crfn} onChange={onChange}/>
        </Form.Group>,
        <Form.Group inline className={!cema.isCopy ? 'hide-me' : ''}>
          <FieldInput label='Mtg. Tax Paid' name='mortgageTaxPaid' value={cema.mortgageTaxPaid} type='number' onChange={onChange}/>
          </Form.Group>
          ]
        ) : null}
      { !cema.isNoNewMoney ? (
        <Divider/>
      ) : null }
      <Form.Group inline>
        <FieldInput label='Cons. Amount' name='consolidatedAmount' value={cema.consolidatedAmount} type='number' onChange={onChange} error={cemaError.consolidatedAmount} errorMessage={OverrideErrorMessage}/>
        <FieldInput label='CEMA Date' name='cemaDate' value={cema.cemaDate} type='date' onChange={onChange} error={cemaError.cemaDate} errorMessage={OverrideErrorMessage}/>
        <FieldCheckbox width={2} label='Recorded' name='isCEMACopy' onChange={onChange} checked={cema.isCEMACopy}/>
      </Form.Group>
      <Form.Group inline className={!cema.isCEMACopy ? 'hide-me' : ''}>
        <FieldInput label='Recorded Date' name='cemaRecordedDate' value={cema.cemaRecordedDate} type='date' onChange={onChange}/> 
        <FieldInput label='CRFN#' name='cemaCRFN' value={cema.cemaCRFN} onChange={onChange}/>
      </Form.Group>
    </Form.Group>
  )
  
export const AssignmentTransferFieldGroup = ({aTransfer, aTransferError, onChange}) => (
    <Form.Group grouped>
      <Form.Group inline>
        <FieldInput type='dropdown' width={14} label='Assignee' name='assignee' value={aTransfer.assignee} onChange={onChange} error={aTransferError.assignee} />
        <FieldCheckbox width={2} label='MERS' name='isMers' onChange={onChange} checked={aTransfer.isMers}/>
      </Form.Group>
      <Form.Group inline>
        <FieldInput label='Date' name='date' value={aTransfer.date} type='date' onChange={onChange} error={aTransferError.date} errorMessage={OverrideErrorMessage}/> 
        <FieldCheckbox width={9} label='Recorded' name='isCopy' onChange={onChange} checked={aTransfer.isCopy}/>
      </Form.Group>
      <Form.Group inline className={!aTransfer.isCopy ? 'hide-me' : ''}>
        <FieldInput width={7} label='Recorded Date' name='recordedDate' value={aTransfer.recordedDate} type='date' onChange={onChange}/> 
        <FieldInput width={7} label='CRFN#' name='crfn' value={aTransfer.crfn} onChange={onChange}/>
      </Form.Group>
    </Form.Group>
  )
  
export const MersTransferFieldGroup = ({mTransfer, mTransferError, onChange}) => (
    <Form.Group grouped>
      <Form.Group inline>
        <FieldInput type='dropdown' width={14} label='Mortgagee' name='assignee' value={mTransfer.assignee} onChange={onChange} error={mTransferError.assignee} />  
        <FieldCheckbox width={2} label='MERS' name='isMers' onChange={onChange} checked={mTransfer.isMers}/>
      </Form.Group>
      <Form.Group inline>
        <FieldInput label='Date' name='date' value={mTransfer.date} type='date' onChange={onChange} error={mTransferError.date} errorMessage={OverrideErrorMessage}/>
      </Form.Group>
    </Form.Group>
  )